<div class="drill-container">
    <mat-form-field appearance="outline" [ngClass]="{'resize-form-field': selectedDrillingId}">
        <mat-label>Select Drill</mat-label>
        <input type="text" matInput [matAutocomplete]="drillingOptions" [formControl]="drillingControl" (input)="onDrillingInput($event)"/>
        <mat-autocomplete #drillingOptions="matAutocomplete" [displayWith]="displayDrillingName" (optionSelected)="onDrillingSelected($event)">
            <mat-option *ngFor="let option of drillingDataOptions" [value]="option">
                {{ option.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <p *ngIf="!subdomainId">Please select Subdomain prior selecting Drill.</p>
</div>
<mat-tab-group (selectedTabIndexChange)="tabChange($event)">
  <mat-tab label="Fielder Analytics" >
    <ng-template matTabContent>
      <app-fielder-analytics></app-fielder-analytics>
    </ng-template>
  </mat-tab>

  <mat-tab label="Holes & Drilled Holes">
    <ng-template matTabContent>
      <app-holes-n-drilled-holes></app-holes-n-drilled-holes>
    </ng-template>
  </mat-tab>
  <mat-tab label="Drilled Holes by Drilled Length">
    <ng-template matTabContent>
      <app-drilled-holes-by-drilled-length></app-drilled-holes-by-drilled-length>
    </ng-template>
  </mat-tab>
  <mat-tab label="Utilization on Shot">
    <ng-template matTabContent>
      <app-utilization-on-shot></app-utilization-on-shot>
    </ng-template>
  </mat-tab>
  <mat-tab label="Fielder Analytics by Drill">
    <ng-template matTabContent>
      <app-fielder-analytics-by-drill></app-fielder-analytics-by-drill>
    </ng-template>
  </mat-tab>
</mat-tab-group>
