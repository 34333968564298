export interface OperationalInputDataParams {
	id: number;
	burden: number;
	spacing: number;
	d90: number;
	d50: number;
	d10: number;
	tpoh: number;
	stvc: number;
	benchId: string;
	blastingVendor: string;
	blastedTon: number;
	numberOfRows: number;
	faceHeights: number;
	burdenToSpacingRatio: number;
	averageHoleDiameter: number;
	patternYieldLayout: number;
	explosiveProduct: string;
	powderFactorVolume: number;
	powderFactorWeight: number;
	stiffnessFactor: number;
	faceAzimuth: number;
	backBreakScore: number;
	loaderOpScore: number;
	loaderCycleTime: number;
	fleetCycleTime: number;
	secondaryBreakagePercent: number;
	truckloadThroughputTime: number;
	rockBreakerTime: number;
	drillingCostPerTon: number;
	explosivesCostPerTon: number;
	secondaryBreakageCostPerTon: number;
	apiV2DatasetId: number;
	createdAt: string;
	updatedAt: string;
	domainId: number;
	subdomainId: number;
	siteId: number;
	datasetName: string;
	dateTime: string;
}

export interface OperationalOutputDataParams {
	d50: number,
	d10: number,
	d90: number,
	spacing: number,
	domainId: number,
	subdomainId: number,
	siteId: number,
	datasetId: number,
	datasetName: string,
	numberOfRows: number,
	faceHeights: number,
	patternBurden: number,
	powderFactorWeight: number,
	drillingCostPerTon: number,
	explosivesCostPerTon: number,
	secondaryBreakageCost: number,
	datasetCreatedAt: string,
	siteName: string,
}

export interface DataSourceSchema {
	key: string;
	type: string;
	label: string;
	readonly?: boolean;
	required?: boolean;
	valueUnit?: string;
}

export const inputDataSourceCollectionSchema: DataSourceSchema[] = [
	{ key: 'dateTime', type: 'date', label: 'Date' },
	{ key: 'benchId', type: 'string', label: 'Bench ID' },
	{ key: 'blastingVendor', type: 'string', label: 'Blasting Vendor' },
	{ key: 'blastedTon', type: 'number', label: 'Blasted Tons' },
	{ key: 'faceHeight', type: 'number', label: 'Face Height' },
	{ key: 'burdenToSpacingRatio', type: 'number', label: 'Burden to Spacing Ratio' },
	{ key: 'patternYieldLayout', type: 'number', label: 'Pattern Yield Layout' },
	{ key: 'explosiveProduct', type: 'number', label: 'Explosive Product' },
	{ key: 'stiffnessFactor', type: 'number', label: 'Stiffness Factor' },
	{ key: 'faceAzimuth', type: 'number', label: 'Face Azimuth' },
	{ key: 'backBreakScore', type: 'number', label: 'Back Break Score' },
	{ key: 'loaderOpScore', type: 'number', label: 'Loader Op. Score' },
	{ key: 'loaderCycleTime', type: 'number', label: 'Loader Cycle Time' },
	{ key: 'fleetCycleTime', type: 'number', label: 'Fleet Cycle Time' },
	{ key: 'secondaryBreakagePercent', type: 'number', label: 'Secondary Breakage %' },
	{ key: 'truckloadThroughputTime', type: 'number', label: 'Truckload Throughput Time' },
	{ key: 'rockBreakerTime', type: 'number', label: 'Rock Breaker Time' },
	{ key: 'stvc', type: 'text', label: 'STVC' },
]

export const outputDataSourceCollectionSchema: DataSourceSchema[] = [
	{ key: 'numberOfRows', type: 'number', label: 'Row Count', readonly: true },
	{ key: 'patternBurden', type: 'number', label: 'Pattern Burden', readonly: true },
	{ key: 'spacing', type: 'number', label: 'Spacing', readonly: true },
	{ key: 'powderFactorVolume', type: 'number', label: 'Powder Factor Volume', readonly: true, valueUnit: 'powderFactorVolume' },
	{ key: 'powderFactorWeight', type: 'number', label: 'Powder Factor Weight', readonly: true, valueUnit: 'powderFactorWeight' },
	{ key: 'averageHoleDiameter', type: 'number', label: 'Hole Diameter', readonly: true, valueUnit: 'smallDistance' },
	{ key: 'd10', type: 'number', label: 'D10', readonly: true, valueUnit: 'smallDistance' },
	{ key: 'd50', type: 'number', label: 'D50', readonly: true, valueUnit: 'smallDistance' },
	{ key: 'd90', type: 'number', label: 'D90', readonly: true, valueUnit: 'smallDistance' },
	{ key: 'tonsPerHour', type: 'number', label: 'Tons Per Hour', readonly: true, valueUnit: 'hour' },
	{ key: 'drillingCostPerTon', type: 'number', label: 'Drilling CPT', readonly: true },
	{ key: 'explosivesCostPerTon', type: 'number', label: 'Explosive CPT', readonly: true },
	{ key: 'secondaryBreakageCost', type: 'number', label: '2nd Breakage CPT', readonly: true },
]