import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class SiteService {
	public readonly siteId$ = new BehaviorSubject<number>(null);
	public readonly domainId$ = new BehaviorSubject<number>(null);
	public readonly subDomainId$ = new BehaviorSubject<number>(null);
	public readonly selectedTimeline$ = new BehaviorSubject<string>(null);
	public readonly startDate$ = new BehaviorSubject<string>(null);
	public readonly endDate$ = new BehaviorSubject<string>(null);
	public readonly selectedDrillMachine$: Subject<string> = new Subject();
	public readonly activeSideNav$ = new BehaviorSubject<string>('drillAndBlast');
	public readonly activeDrillTab$ = new BehaviorSubject<number>(0);
	public readonly seletedSiteName$ = new BehaviorSubject<string>(null);

	constructor() { }

	public get activeSideNav() {
		return this.activeSideNav$.value;
	}

	public get activeDrillTab() {
		return this.activeDrillTab$.value;
	}
  
}
