import { Pipe, PipeTransform } from '@angular/core';
import { updatedTypeMeta } from '@app/dashboard-measurement/types';
import { measurement } from '@lib/measurement';
import { Unit } from '@lib/measurement/units';

@Pipe({
	standalone: true,
	name: 'UnitDisplayPipe'
})
export class UnitDisplayPipe implements PipeTransform {
	transform(value: string, type: string, valueUnit: string): string {
		if (valueUnit) {
			const toUnit:Unit = type == 'metric' ? updatedTypeMeta[valueUnit].metricUnit : updatedTypeMeta[valueUnit].imperialUnit;
			const unit = measurement.abbreviation(toUnit);
			value = value + ' (' + unit + ')';
		}
		return value;
	}
}
