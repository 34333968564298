class StorageLocal{
	private readonly keyPrefix='frontend-';

	public get(key:string){
		key=this.keyPrefix+key;
		let value:any=localStorage.getItem(key);
		if(value===null || value==='undefined')
			return null;
		value=JSON.parse(value);
		return value;
	}

	public set(key:string,value:any){
		key=this.keyPrefix+key;
		value=JSON.stringify(value);
		localStorage.setItem(key,value);
	}

	public delete(key:string){
		key=this.keyPrefix+key;
		localStorage.removeItem(key);
	}

	public clear(){
		for(let i=0;i<localStorage.length;){
			let key=localStorage.key(i);
			if(key.indexOf(this.keyPrefix)==0)
				localStorage.removeItem(key);
			else
				++i;
		}
	}
};

export const storageLocal=new StorageLocal();
export { storageLocal as default };
