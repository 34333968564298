import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from '@app/gui/dashboard/dashboard.component';
import { userGuard } from './app-state/user.guard';
import { LoginComponent } from '@app/gui/login/login.component';

const routes: Routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [userGuard]
	},
	{
		path: 'login',
		component: LoginComponent
	},
  	{
		path: '**',
		redirectTo: '/dashboard',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
