import { Pipe, PipeTransform } from '@angular/core';
import { updatedTypeMeta } from '@app/dashboard-measurement/types';
import { measurement } from '@lib/measurement';
import { Unit, unitMeta } from '@lib/measurement/units';

@Pipe({
	standalone: true,
	name: 'UnitConversionPipe',
	pure: false
})
export class UnitConversionPipe implements PipeTransform {
	transform(value: number, type: string, valueUnit: string, convertToMetric?: boolean): number {
		if(typeof value != 'number') return value;

		if (valueUnit) {
			const toUnit:Unit = type == 'imperial' ? updatedTypeMeta[valueUnit].imperialUnit : updatedTypeMeta[valueUnit].metricUnit;     
			let convertedValue = measurement.convert(Number(value), toUnit);
			if (convertToMetric) {
				const unit = unitMeta[toUnit];
				convertedValue = (value-unit.offsetFromBase)*unit.inBase;
			}
			return convertToMetric ? convertedValue : parseFloat(convertedValue?.toFixed(2));
		}
		else{
			return parseFloat(value?.toFixed(2));
		}
	}
}
