import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SupportButtonService } from '@app/gui/support-button.service';
import { environment } from '@environments/environment';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
	public title = environment.vendor;
	public favIcon: HTMLLinkElement = document.querySelector('#appIcon');

	constructor(
		private readonly iconRegistry: MatIconRegistry,
		private readonly sanitizer: DomSanitizer,
		private readonly supportButton:SupportButtonService
	) {
	}

	ngOnInit(): void {
		if (self !== top) {
			top.location = self.location;
		}

		if (environment.vendor === 'Strayos') {
			this.favIcon.href = 'favicon.ico';
			this.iconRegistry.addSvgIcon('brandLogo', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/new-strayos.svg'));
		} else {
			this.favIcon.href = 'favicon-adl.ico';
			this.iconRegistry.addSvgIcon('brandLogo', this.sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/adl.svg'));
		}

		this.iconRegistry.setDefaultFontSetClass("material-icons-outlined");
	}

	ngAfterViewInit() {
		this.supportButton._ngAfterViewInit();
	}
}
