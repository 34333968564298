import { DrillAndBlastCostsParams } from "@app/models/drill-and-blast-costs.model";
import { BehaviorSubject, of, switchMap, take } from "rxjs";
import { getPromise, postPromise, putPromise } from "./request-promise";

export const drillAndBlastCostsData$ = new BehaviorSubject<DrillAndBlastCostsParams[]>(null);
export const loadingDrillAndBlastCostsData$ = new BehaviorSubject<boolean>(false);

export function getDrillAndBlastCosts(siteId: number) {
	return getPromise(
		`drill_and_blast_costs?api_v2_site_id=${siteId}`,
		(data: DrillAndBlastCostsParams) => data || null
	);
}

export function getDrillAndBlastCostsByDomain(domainId: number) {
	return getPromise(
		`drill_and_blast_costs/get_drill_and_blast_costs_by_domain?domain_id=${domainId}`,
		(data) => data).then((details) => {
			drillAndBlastCostsData$.next(details);
			loadingDrillAndBlastCostsData$.next(false);
			return details;
		});
}

export function createDrillAndBlastCosts(drillAndBlastCostsParams:DrillAndBlastCostsParams) {
	return postPromise(
		'drill_and_blast_costs',
		drillAndBlastCostsParams,
		(data: DrillAndBlastCostsParams) => data || null
	);
}

export function updateDrillAndBlastCosts(drillAndBlastCostsParams:DrillAndBlastCostsParams) {
	return putPromise(
		`drill_and_blast_costs/${drillAndBlastCostsParams.id}`,
		drillAndBlastCostsParams,
		(data: DrillAndBlastCostsParams) => data || null
	);
}

export function getCacheDrillAndBlastCostsByDomain(domainId: number) {
	return drillAndBlastCostsData$.pipe(
		take(1),
		switchMap((details) => {
		loadingDrillAndBlastCostsData$.next(true);
		if (details === null) {
			return getDrillAndBlastCostsByDomain(domainId);
		}
		loadingDrillAndBlastCostsData$.next(false);
		return of(details);
		}),
	);
}