import { OperationalInputDataParams, OperationalOutputDataParams } from "@app/models/operational-data-collection";
import { BehaviorSubject } from "rxjs";
import { getPromise, postPromise, putPromise } from "./request-promise";

export const operationalOutputDataCollection$ = new BehaviorSubject<OperationalOutputDataParams[]>(null);
export const operationalInputDataCollection$ = new BehaviorSubject<OperationalInputDataParams[]>(null);

export function getOperationalInputDataCollection(domainId: number) {
	return getPromise(
		`operational_collections?domain_id=${domainId}`,
		(data) => data || null
	);
}

export function getOperationalOutputDataCollection(domainId: number) {
	return getPromise(
		`operational_collections/output?domain_id=${domainId}`,
		(data) => data || null
	);
}

export function updateOperationalDataCollectionField(id: number, updatedData) {
	return putPromise(
		`operational_collections/${id}`,
		updatedData ,
		(data) => data || null
	);
}

export function createOperationalInputData(data) {
	return postPromise(
		`operational_collections`,
		data ,
		(data) => data || null
	);
}