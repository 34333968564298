import { Component, OnDestroy, OnInit } from '@angular/core';

import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { ChartDataInterface, FielderAnalyticsInterface } from "@app/models/chart-data.model";
import * as charts from '@utilities/highchart-objectify';

import * as Highcharts from 'highcharts/highstock';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-fielder-analytics',
	templateUrl: './fielder-analytics.component.html',
	styleUrls: ['./fielder-analytics.component.scss']
})
export class FielderAnalyticsComponent implements OnInit, OnDestroy {

	public Highcharts: typeof Highcharts = Highcharts; // required

	public chartOptions = []; // required
	private subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string;

	private fielderAnalytics:ChartDataInterface[] = [];

	constructor(
		private chartDataService:ChartDataService,
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService,
		private userService: UserService) {
	}

	ngOnInit() {
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loading => {
			this.loading = loading;
		}));

		this.subscriptions.add(this.chartDataService.fielderAnalytics$.subscribe((res) => {
			this.triggerLoadData(res);
		}));

		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.getReport();
			}
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));
		
		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		})); 

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe(() => {
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.selectedDrillMachine$.subscribe(() => {
			this.chartDataService.getAllFielderAnalytics();
		}));
	}
	private async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheFielderAnalytics().subscribe((res) => {
			this.triggerLoadData(res);
		}));
	}

	private loadData = (chartData: ChartDataInterface[])=> {
		this.fielderAnalytics = chartData;
		const data:FielderAnalyticsInterface = this.generateChartDataService.generateChartData(this.fielderAnalytics);
		for (const key in data?.fielderAnalyticsData) {
			this.chartOptions = charts.generateChartOptions(data.fielderAnalyticsData[key]);
		}
	}

	private triggerLoadData(data? : ChartDataInterface[]) {
		const originalFielderAnalytics = data || this.generateChartDataService.originalFielderAnalytics;
		this.fielderAnalytics = this.generateChartDataService.getFilteredData(
			originalFielderAnalytics,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline
		);

		this.loadData(this.fielderAnalytics);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
