import { Injectable, NgZone, Provider } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as backend from '@app/backend';
import { getStrayosDashboardLicense } from "@app/backend/license";
import { User } from '@app/models/user.model';
import storageLocal from "@utilities/storage-local";
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	static providers:Provider[]=[
		{
			provide: User,
			useFactory: (userService: UserService) => userService.user,
			deps: [UserService]
		},
	];

	[x: string]: any;
	public readonly user$ = new BehaviorSubject<User>(null);
 	public userUnitMeasurement$ = new BehaviorSubject<string>('metric');

	public get user() {
		return this.user$.value;
	};

	public readonly userDomainInfo$ = new BehaviorSubject<backend.UserDomainInfo>(null);

	public get userDomainInfo() {
		return this.userDomainInfo$.value;
	};

	constructor(
		private readonly ngZone: NgZone,
		private snackBar: MatSnackBar
	) {}

	public async login(credentials: { email: string; password: string }) {
		const response = await backend.postPromise("api/v1/sessions", credentials);

		if (response) {
			const user=new User(response);
			this.user$.next(user);
			this.userUnitMeasurement$.next(user.measurementUnit);
			storageLocal.set("email", user.email);
			storageLocal.set("authenticationToken", user.authenticationToken);

			const hasValidLicense = await getStrayosDashboardLicense(user.id);
			if (!hasValidLicense) {
				this.snackBar.open('User License is either expired or not found', ' ', { horizontalPosition: 'right', verticalPosition: 'top' });
				return null;
			}

			if (hasValidLicense) {
				this.getUserDomainInfo();
			}

			return user;
		} else {
			this.snackBar.open('Login Failed', ' ', { horizontalPosition: 'right', verticalPosition: 'top' });
		}

		return null;
	}

	public async loggedIn() {
		if (this.user)
			return true;

		return await this.loginFromStorage();
	}

	private async loginFromStorage(){
		const email=storageLocal.get("email");
		const token=storageLocal.get("authenticationToken");
		if (!email || !token)
			return null;
	
		const r=await backend.userAuthenticateToken(email,token);
		if(!r)
			return null;
	
		const user=new User(r);
		this.userUnitMeasurement$.next(user.measurementUnit);
		this.user$.next(user);
		this.getUserDomainInfo();
		return user;
	}

	public async logout() {
		await backend.deletePromise('api/v1/sessions');
		this.user$.next(null);
		localStorage.clear();

		window.location.href = '/';
	}
	private async getUserDomainInfo() {
		const userDomainInfo = await backend.getUserDomainInfo();

		if (userDomainInfo) {
			this.userDomainInfo$.next(userDomainInfo);
		}
	}

	public async getStrayosDashboardLicense(user: User) {
		const response = await backend.getStrayosDashboardLicense(user.id);

		if (response.isValidLicense) {
			return true;
		}

		return false;
	}

	public saveUser(success?: (message:string) => void, failure?: (message:string) => void) {
		let user = this.user;
		let updatedUser = {
			id: user.id,
			measurement_unit: user.measurementUnit,
		};
		user.setUnits();
		backend.putPromise(`api/v1/users/${updatedUser.id}`, updatedUser, () => {
			this.userUnitMeasurement$.next(updatedUser.measurement_unit);
			if(success)
				console.log('User Updated Successfully.');
		});0
	}

}

