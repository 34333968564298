import { Type, typeMeta } from '@lib/measurement/types';

const hour = {
	valueUnit: 'hour',
	metricUnit: 'hour',
	imperialUnit: 'hour',
 };

const minute = {
	valueUnit: 'minute',
	metricUnit: 'minute',
	imperialUnit: 'minute',
};

const powderFactorVolume = {
	valueUnit: 'kilogramPerMeter3',
	metricUnit: 'kilogramPerMeter3',
	imperialUnit: 'poundsPerYard3',
};

export const updatedTypeMeta = {
	...typeMeta,
	hour,
	minute,
	powderFactorVolume,
};
  
type types = Type | 'hour' | 'minute' | 'powderFactorVolume';

export const measurementTypes = <types[]>Object.keys(updatedTypeMeta);
