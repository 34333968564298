import { graphqlUrl, RequestOptions, xmlHttpRequest } from "./request-shared";

export function getPromise<T=any>(
	url:string,
	mapData:(data:any)=>T=(data=>data),
	options:RequestOptions={},
){
	return new Promise<T>(resolve=>{
		xmlHttpRequest<T>({
			...options,
			url,
			method: 'get',
			success: data=>resolve(mapData(data)),
			failure: ()=>resolve(null),
		});
	});
}

export function postPromise<T=any>(
	url:string,
	body:{}|FormData|Blob|null,
	mapData:(data:any)=>T=(data=>data),
	options:RequestOptions={},
){
	return new Promise<T>(resolve=>{
		xmlHttpRequest<T>({
			...options,
			url,
			method: 'post',
			body,
			success: data=>resolve(mapData(data)),
			failure: ()=>resolve(null),
		});
	});
}

export function putPromise<T=any>(
	url:string,
	body:{}|FormData|Blob|null,
	mapData:(data:any)=>T=(data=>data),
	options:RequestOptions={},
){
	return new Promise<T>(resolve=>{
		xmlHttpRequest<T>({
			...options,
			url,
			method: 'put',
			body,
			success: data=>resolve(mapData(data)),
			failure: ()=>resolve(null),
		});
	});
}

export function deletePromise<T=any>(
	url:string,
	mapData:(data:any)=>T=(data=>data),
	options:RequestOptions={},
):Promise<T>{
	return new Promise<T>(resolve=>{
		xmlHttpRequest<T>({
			...options,
			url,
			method: 'delete',
			success: data=>resolve(mapData(data)),
			failure: ()=>resolve(null),
		});
	});
}

export function graphqlPromise<T=any>(
	operationName:string,
	query:string,
	variables:object,
	mapData:(data:any)=>T=(data=>data),
	options:RequestOptions={},
){
	const body={
		operationName,
		query,
		variables,
	};
	return new Promise<T>(resolve=>{
		xmlHttpRequest<T>({
			...options,
			url: graphqlUrl,
			method: 'post',
			body,
			success: data=>resolve(mapData(data)),
			failure: ()=>resolve(null),
		});
	});
}