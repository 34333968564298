import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/app-state/user.service';

@Component({
	selector: 'avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
	public initials: string;
	public avatarUrl: string;

	constructor(
		private readonly userService: UserService,
	){
	}

	ngOnInit(): void {
		const user = this.userService.user;

		if (user) {
			this.initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
			this.avatarUrl = user.avatarUrl;
		}
	}
}
