import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

type Corner='right-top'|'right-bottom'|'left-bottom';

@Injectable({
	providedIn: 'root',
})
export class SupportButtonService{
	private intervalHandle:number;
	private buttonIframe:HTMLIFrameElement;
	private active=false;
	constructor(){
		if(!document.getElementById('ze-snippet')){
			this.active=true;
			let script=document.createElement('script');
			script.id='ze-snippet';
			script.src='https://static.zdassets.com/ekr/snippet.js?key='+environment.zendeskKey;
			document.getElementsByTagName('body')[0].appendChild(script);
		}
	}

	_ngAfterViewInit(){
		//not a real implimentation of this function. It's called by app.component
		if(!this.active)
			return;

		//yes this is a terrible, terrible sin against good development but if you know a better way to find that iframe fix it -John Lockwood
		this.intervalHandle=window.setInterval(()=>{
			const iframes=document.getElementsByTagName('iframe');
			for(let i=0;i<iframes.length;++i){
				if(iframes[i].id==='launcher'){
					this.buttonIframe=iframes[i];
					break;
				}
			}
			if(this.buttonIframe){
				clearInterval(this.intervalHandle);
				this.buttonIframe.style.margin="8px";
				this.buttonIframe.style.height="40px";
				this.buttonIframe.style.borderRadius="20px";
				this.buttonIframe.style.boxShadow="0 0 4px #00000088";

				const style=this.buttonIframe.contentDocument.createElement('style');
				style.type='text/css';
				style.innerHTML=`
					button{
						padding: 10px 10px !important;
						background-color: #7080FF !important;
					}

					button.u-userLauncherColor:not([disabled]){
						background-color: #7080FF !important;
					}
				`;
				this.buttonIframe.contentDocument.getElementsByTagName('body')[0].append(style);
			}
		},100);
	}

	public positionRelativeToPoint(x:number, y:number){
		if(this.buttonIframe){
			this.buttonIframe.style.bottom=`${window.innerHeight-y}px`;
		}
	}
}
