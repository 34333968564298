import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user.model';
import { UserService } from '@app/app-state/user.service';
import { MatDialog } from '@angular/material/dialog';

import { UnitSettingsDialog } from "@app/gui/components/unit-settings-dialog/unit-settings-dialog.component";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
	public user:User;

	constructor(
		public readonly userService:UserService,
		private readonly dialog: MatDialog,
	) {
	}

	ngOnInit(): void {
		this.user = this.userService.user;
	}

	public logout() {
		this.userService.logout();
	}

	public unitSettings() {
		this.dialog.open(UnitSettingsDialog, {
				width: '600px', 
				disableClose: true, 
		});
	}
}
