<div class="">
	<div class="header-container">
		<button mat-raised-button color="primary" (click)="addDesignBurdens()" [disabled]="!dataSource?.data?.length">Save Design Burdens</button>
	</div>
	<div class="content-container">
		<table mat-table [dataSource]="dataSource.data" class="mat-elevation-z8">
			
			<!-- Project Column -->
			<ng-container matColumnDef="project">
				<th mat-header-cell *matHeaderCellDef> Project </th>
				<td mat-cell *matCellDef="let element"> {{element.project}} </td>
			</ng-container>

			<!-- Average Burden Column -->
			<ng-container matColumnDef="averageBurden">
				<th mat-header-cell *matHeaderCellDef> Average Pattern Burden </th>
				<td mat-cell *matCellDef="let element"> {{element.averageBurden}} </td>
			</ng-container>
		
			<!-- Design Burden Column -->
			<ng-container matColumnDef="designBurden">
				<th mat-header-cell *matHeaderCellDef> Design Burden</th>
				<td mat-cell *matCellDef="let element; let i = index">
					<input matInput [(ngModel)]="element.designBurden" type="number" (change)="onInputChange()">
				</td>
			</ng-container>
		
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
		
		<!-- Paginator -->
		<mat-paginator
			pageSize="30"
			[pageSizeOptions]="[30, 40]"
			hidePageSize="true"
		>
		</mat-paginator>
		
		<ng-container *ngIf="loading">
			<div class="loading-logo">
			<mat-spinner></mat-spinner>
			</div>
		</ng-container>
	</div>	  
</div>