export const environment = {
	production: false,
    name: '',
    parentUrl: '',
    accountsLoginUrl: 'https://accounts.strayos.com/login.html',
    baseUrl: '',
    apiUrl: 'http://localhost:3000',
    apiPrefix: 'api',
    zendeskKey: 'db9b0d66-ca26-4267-a677-6f1c3fe7d023',
    vendor: 'Strayos',
    powerbiEmbedTokenUrl: '',
    accountsLoginRedirectUrl() {
        return `${this.accountsLoginUrl}?return_to=${this.baseUrl}&environment=${this.name}&api_url=${this.apiUrl}`
    },
    pbWorkspaceId: '',
    drillAndBlastDashboardReportId: '',
    fielderDashboardReportId: '',
    fragmentationDashboardReportId: '',
};
