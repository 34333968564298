<app-header>
    <div class="filters">
        <mat-form-field appearance="outline" [ngClass]="{'resize-form-field': selectedDomainId}">
            <mat-label>Select Domain</mat-label>

            <input type="text" matInput [matAutocomplete]="domainOptions" [formControl]="domainControl" (input)="onDomainInput($event)"/>
            <mat-autocomplete #domainOptions="matAutocomplete" [displayWith]="displayDomainNameFn" (optionSelected)="onDomainSelect()">
                <mat-option *ngFor="let domain of filteredDomains | async" [value]="domain">{{domain.domainName}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
        
        <mat-form-field appearance="outline" [ngClass]="{'resize-form-field': selectedDomainId}">
            <mat-label>Select Subdomain</mat-label>

            <input type="text" matInput [matAutocomplete]="subdomainOptions" [formControl]="subdomainControl" (input)="onSubDomainInput($event)"/>
            <mat-autocomplete #subdomainOptions="matAutocomplete" [displayWith]="displaySubdomainNameFn" (optionSelected)="onSubdomainSelect()">
                <mat-option *ngFor="let subdomain of filteredSubdomains | async" [value]="subdomain">{{subdomain.subdomainName}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" [ngClass]="{'resize-form-field': selectedDomainId}">
            <mat-label>Select Site</mat-label>

            <input type="text" matInput  [matAutocomplete]="siteOptions" [formControl]="siteControl" (input)="onSiteInput($event)"/>
            <mat-autocomplete #siteOptions="matAutocomplete" [displayWith]="displaySiteNameFn" (optionSelected)="onSiteSelect()">
                <mat-option *ngFor="let site of filteredSites | async" [value]="site">{{site.name}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <ng-container *ngIf="selectedDomainId">
            <div class="content-wrapper">
                <div class="label-wrapper">
                    <label>Date Range:</label>
                </div>
                <div class="date-picker-wrapper">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" placeholder="MM/DD/YYYY" [value]="selectedStartDate" (dateChange)="onDateChange($event, 'startDate')">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>
        
                        <mat-form-field appearance="outline">
                            <mat-label>End Date</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" placeholder="MM/DD/YYYY" [value]="selectedEndDate"  [disabled]="!selectedStartDate"  (dateChange)="onDateChange($event, 'endDate')">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                </div>

            </div>
            
            <div class="timeline-container content-wrapper">
                <div class="label-wrapper">
                    <label>Timeline:</label>
                </div>
                <mat-button-toggle-group class="circle-group">
                    <mat-button-toggle *ngFor="let option of options" [value]="option" class="circle-toggle"
                                       [ngClass]="{'selected': selectedTimeline === option}"
                                       (click)="toggleSelection(option)">
                      <span>{{ option }}</span>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
            </div>
        </ng-container>
    </div>

</app-header>

<mat-drawer-container class="parent-container">
    <mat-drawer #drawer mode="side" class="sidenav-container" opened="true">
        <div class="logo-container">
            <button mat-icon-button (click)="navigateToDomain()" class="logo">
                <mat-icon svgIcon="brandLogo"></mat-icon>
            </button>
        </div>
        <mat-button-toggle-group class="sidenav-items" [(ngModel)]="selectedItem" (ngModelChange)="updateSideNav($event)" vertical="true">
            <mat-button-toggle value="drillAndBlast" matTooltip="Drill and Blast">
                <mat-icon>bar_chart</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle value="fragmentation" matTooltip="Fragmentation">
                <mat-icon>query_stats</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle value="fielder" matTooltip="Fielder">
                <mat-icon>trending_up</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle value="dataCollection" matTooltip="Data Collection View">
                <mat-icon>table</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle value="drillAndBlastCosts" matTooltip="Drill And Blast Costs">
                <mat-icon>attach_money</mat-icon>
            </mat-button-toggle>

            <mat-button-toggle value="operationalDataCollection" matTooltip="Operational Data Collection">
                <mat-icon>dashboard</mat-icon>
            </mat-button-toggle>

        </mat-button-toggle-group>
    </mat-drawer>

    <div class="content-container" [class.sidenav-closed]="!drawer.opened">
        <div class="main-content">
            <div class="domain-loading-container" *ngIf="!domains">
                <mat-spinner></mat-spinner>
                <span>Loading your data. Please wait...</span>

                <div class="loading-logo-overlay">
                    <mat-icon svgIcon="brandLogo" color="primary" class="logo"></mat-icon>

                    <div class="animate"></div>
                </div>
            </div>

            <app-drill-and-blast-dashboard *ngIf="selectedItem === 'drillAndBlast' && selectedDomainId"></app-drill-and-blast-dashboard>

            <app-fragmentation-dashboard *ngIf="selectedItem === 'fragmentation' && selectedDomainId"></app-fragmentation-dashboard>

            <app-fielder-dashboard *ngIf="selectedItem === 'fielder' && selectedDomainId"></app-fielder-dashboard>

            <app-data-collection-view *ngIf="selectedItem === 'dataCollection' && selectedDomainId"></app-data-collection-view>

            <app-drill-and-blast-costs *ngIf="selectedItem === 'drillAndBlastCosts' && selectedDomainId"></app-drill-and-blast-costs>

            <app-operational-data-collection *ngIf="selectedItem === 'operationalDataCollection' && selectedDomainId"></app-operational-data-collection>
        </div>
    </div>
</mat-drawer-container>

