import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { UserService } from '@app/app-state/user.service';
import { environment } from '@environments/environment.base';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public loginForm: FormGroup;
	public loggingIn=false;

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		public userService: UserService,
	) {

	}

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]],
			password: ['', [Validators.required, Validators.minLength(8)]],
		});
	}

	public navigateToSignup() {
		window.location.href = environment.parentUrl;
	}

	public navigateToForgotPassword() {
		window.location.href = `${environment.parentUrl}/forgot-password`;
	}

	public async onLogin() {
		// window.location.href = environment.accountsLoginRedirectUrl();
		// window.location.href = "https://accounts.strayos.com/login.html?return_to=http://localhost:4201&environment=local&api_url=https://devx.strayos.com:3020";

		const formModel = this.loginForm.value;
		if(this.loginForm.valid && !this.loggingIn){
			this.loggingIn = true;

			const response = await this.userService.login(formModel);

			if (response){
				this.loggingIn=false;
				this.router.navigateByUrl('/dashboard');
			} else {
				this.loggingIn=null;
			}
		}
	}
}
