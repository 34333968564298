import * as backend from '@app/backend';
import { measurement } from '@lib/measurement';
import { BehaviorSubject } from '@rxjs';
import { measurementTypes } from "../dashboard-measurement/types";

export class User{
	public address:string;
	public authenticationToken:string;
	public avatarUrl:string;
	public companyAddress:string;
	public companyName:string;
	public email:string;
	public firstName:string;
	public id:number;
	public industry:string;
	public lastName:string;
	public measurementUnit:'imperial'|'metric';
	public name:string;
	public phone:string;
	public blasterSignatureUrl:string;
	public receiveProjectEmails:boolean;
	public monthlyProjectLimit:number;
	public isTrialUser:boolean;
	public roundOff:boolean=false;
	public designerLogoUrl:string;
	public customerLogoUrl:string;
	public designerName:string;
	public customerName:string;
	public isSuperAdmin:boolean;

	private readonly unitsIndividual$=new Map(measurementTypes.map(type=>[type,new BehaviorSubject<measurement.Unit>(undefined)]));
	public readonly units$=new BehaviorSubject<measurement.TypeToUnit>(undefined);

	public get units(){
		return this.units$.getValue();
	}
	
	public constructor(args:User.Args){
		this.address=args.address;
		this.authenticationToken=args.authenticationToken;
		this.avatarUrl=args.avatarUrl;
		this.companyAddress=args.companyAddress;
		this.companyName=args.companyName;
		this.email=args.email;
		this.firstName=args.firstName;
		this.id=args.id;
		this.industry=args.industry;
		this.lastName=args.lastName;
		this.measurementUnit=args.measurementUnit;
		this.name=args.name;
		this.phone=args.phone;
		this.blasterSignatureUrl=args.blasterSignatureUrl;
		this.receiveProjectEmails=args.receiveProjectEmails;
		this.monthlyProjectLimit=args.monthlyProjectLimit;
		this.isTrialUser=args.isTrialUser;
		this.isSuperAdmin=args.isSuperAdmin;
		this.designerLogoUrl=args.designerLogoUrl;
		this.customerLogoUrl=args.customerLogoUrl;
		this.designerName=args.designerName;
		this.customerName=args.customerName;
		this.setUnits();
	}

	public unit(type:'distance'):'meter'|'feet'|'usfeet';
	public unit(type:measurement.Type):measurement.Unit;
	public unit(type:measurement.Type):measurement.Unit{
		const unit$=this.unitsIndividual$.get(type);
		if(unit$)
			return unit$.value;
		return 'none';
	}

	public setUnits() {
		const units=<Record<measurement.Type,measurement.Unit>>{};
		if (this.measurementUnit==='imperial') {
			for(const type of measurementTypes)
				this.unitsIndividual$.get(type).nextIfChanged(measurement.typeMeta[type]?.imperialUnit);
			for(const type of measurementTypes)
				units[type]=measurement.typeMeta[type]?.imperialUnit;
		} else {
			for(const type of measurementTypes)
				this.unitsIndividual$.get(type).nextIfChanged(measurement.typeMeta[type]?.metricUnit);
			for(const type of measurementTypes)
				units[type]=measurement.typeMeta[type]?.metricUnit;
		}
		this.units$.next(units);
	}
	
}

export namespace User {
	export type Args=backend.User;
}