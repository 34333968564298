import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { DataSourceSchema } from '@app/models/data-collection.model';
import { inputDataSourceCollectionSchema, OperationalInputDataParams, OperationalOutputDataParams, outputDataSourceCollectionSchema } from '@app/models/operational-data-collection';
import { combineLatest, debounceTime, delay, Subject, Subscription } from 'rxjs';


@Component({
  selector: 'app-operational-data-collection',
  templateUrl: './operational-data-collection.component.html',
  styleUrls: ['./operational-data-collection.component.scss']
})
export class OperationalDataCollectionComponent {
  	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;
	private currentOperationalDataCollection: OperationalInputDataParams;
	private inputDataCollection: OperationalInputDataParams[] = null;
	private outputDataCollection: OperationalOutputDataParams[] = null;
	private subDomainId: number;
	private selectedTimeline: string;
	private startDate: string;
	private endDate: string;
	private inputSubject: Subject<{id: number, value: {}}> = new Subject();
	private subscriptions: Subscription = new Subscription();

	public dataSourceSchema: DataSourceSchema[] = inputDataSourceCollectionSchema;
	public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
	public dataSource: MatTableDataSource<OperationalInputDataParams>;
	public loadingOperationalInputDataCollection: boolean;
	public loadingOperationalOutputDataCollection: boolean;
	public siteDatasetLists: {id: number, name: string}[];
	public isNewRow: boolean;
	public domainId: number;
	public siteId: number;
	public selectedType: string = 'input';
	public userUnitMeasurement: string;

	constructor(
		private snackBar: MatSnackBar,
		private readonly siteService: SiteService,
		private generateChartDataService: GenerateChartDataService,
		private readonly userService: UserService
	) {
		// Combine subDomainId$ and siteId$ streams
		const combinedSub = combineLatest([
			siteService.selectedTimeline$,
			siteService.startDate$,
			siteService.endDate$,
			siteService.subDomainId$,
			siteService.siteId$
		]).pipe(delay(100))
		.subscribe(ids => {
			const [selectedTimeline, startDate, endDate, subDomainId, siteId] = ids;
			this.selectedTimeline = selectedTimeline;
			this.startDate = startDate;
			this.endDate = endDate;
			this.subDomainId = subDomainId;
			this.siteId = siteId;

			this.triggerLoadData();
		});

		this.subscriptions.add(combinedSub);

		this.subscriptions.add(this.inputSubject.pipe(debounceTime(500)).subscribe(value => {
			this.updateInputField(value);
		}));
	}

	ngOnInit() {
		// Read only Fields
		this.displayedColumns.unshift('datasetName');
		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (domainId) {
				this.fetchOperationalInputDataByDomain(domainId);
				if (this.selectedType === 'output') {
					this.fetchOperationalOutputDataByDomain();
				}
			}
		}));

		this.subscriptions.add(backend.operationalInputDataCollection$.subscribe((res)=>{
			this.inputDataCollection = res;
		}));

		this.subscriptions.add(backend.operationalOutputDataCollection$.subscribe((res)=>{
			this.outputDataCollection = res;
		}));

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe((measurement: string) => {
			this.userUnitMeasurement = measurement;
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private async fetchOperationalInputDataByDomain(domainId: number) {
		this.loadingOperationalInputDataCollection = true;
		try {
			const response = await backend.getOperationalInputDataCollection(domainId);
			if (!response || response.length <= 0) {
				this.inputDataCollection = [];
			}
			if (response.length > 0) {
				this.inputDataCollection = response;
			}
			
			this.dataSourceSchema = inputDataSourceCollectionSchema;
			this.displayedColumns = this.dataSourceSchema.map(col => col.key);
			this.displayedColumns.unshift('datasetName');
			backend.operationalInputDataCollection$.next(this.inputDataCollection);
			this.triggerLoadData();
			this.loadingOperationalInputDataCollection = false;
		} catch (error) {
			console.error('Error fetching Operational Data Collection:', error);
			// Handle the error appropriately here
			this.loadingOperationalInputDataCollection = false;
		}
	}

	public onFieldInputClick(event: Event, readOnly: boolean) {
		const target = event.target as HTMLInputElement;
		if (readOnly) {
			target.readOnly = true;
		}
		else{
			target.readOnly = false;
		}
	}

	public async onFieldInputChange(element:OperationalInputDataParams, key: string, value: string){	
		if (!element.apiV2DatasetId) return;
		if (key && value) {
			const updatedValue = {
				[key] : value
			}

			if (!this.isNewRow) {
				const updatedData = {
					id: element.id,
					value: updatedValue
				}
				this.inputSubject.next(updatedData);
			}
			element[key] = value;
		}
	}
	
	private async updateInputField(updatedData) {
		const response = await backend.updateOperationalDataCollectionField(updatedData.id, updatedData.value);
		if (response) {
			this.snackBar.open('Successfully updated', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
		}
	}

	private async initiateOperationalDataCollection() {
		this.loadingOperationalInputDataCollection = true;
		this.siteDatasetLists = await backend.fetchSiteDatasetsList(this.siteId);
		this.isNewRow = true;
		const newRowData: any = {};
		this.dataSourceSchema.forEach((col) => {
			newRowData[col.key] = '';
		});
		newRowData['isNewRow'] = true;
		this.currentOperationalDataCollection = newRowData;
		this.dataSource.data.unshift(this.currentOperationalDataCollection);
		this.dataSource.paginator = this.paginator;
		this.scrollToFirstIndex();
		this.loadingOperationalInputDataCollection = false;
	}

	private scrollToFirstIndex(): void {
		let elem = this.rows.first;
		elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}

	public onDatasetSelect(event) {
		const value = event.value;
		const isCostFoundPerProject = this.inputDataCollection.find(cost => cost.apiV2DatasetId == value.id);
		if (isCostFoundPerProject) {
			this.snackBar.open('A Project should have only one Project. Please select other Project.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
			event.source.value = undefined;
			return;
		}
		this.currentOperationalDataCollection.datasetName = value.name;
		this.currentOperationalDataCollection.apiV2DatasetId = value.id;
	}

	private async addOperationalData() {
		if (!this.currentOperationalDataCollection.apiV2DatasetId) return ;

		const response = await backend.createOperationalInputData(this.currentOperationalDataCollection);
		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			if (response.id) {
				response.dateTime = this.currentOperationalDataCollection.dateTime;
				this.inputDataCollection = [...this.inputDataCollection,response];
				backend.operationalInputDataCollection$.next(this.inputDataCollection);
				this.triggerLoadData();
				this.isNewRow = false;
				this.currentOperationalDataCollection = undefined;
			}
		}
	}

	public selectType(event: MatButtonToggleChange){
		this.selectedType = event.value;
		if (this.selectedType === 'input') {
			this.loadingOperationalInputDataCollection = true;
			this.dataSource.data = [];
			this.getInputData();
		}
		else{
			this.dataSource.data = [];
			this.getOutputData();
		}
	}

	private getInputData(){
		if (this.inputDataCollection) {
			this.dataSourceSchema = inputDataSourceCollectionSchema;
			this.displayedColumns = this.dataSourceSchema.map(col => col.key);
			this.displayedColumns.unshift('datasetName');
			this.triggerLoadData();
			this.loadingOperationalInputDataCollection = false;
		}
		else{
			this.fetchOperationalInputDataByDomain(this.domainId);
		}
	}

	private getOutputData(){
		if (this.outputDataCollection) {
			this.dataSourceSchema = outputDataSourceCollectionSchema;
			this.displayedColumns = this.dataSourceSchema.map(col => col.key);
			this.displayedColumns.unshift('datasetName');
			this.triggerLoadData();
		}
		else{
			this.fetchOperationalOutputDataByDomain();
		}
	}

	private async fetchOperationalOutputDataByDomain(){
		this.loadingOperationalOutputDataCollection = true;
		const response = await backend.getOperationalOutputDataCollection(this.domainId);

		if (!response || response.length <= 0) {
			this.inputDataCollection = [];
		}
		if (response.length >  0) {
			this.outputDataCollection = response;
		}
		backend.operationalOutputDataCollection$.next(this.outputDataCollection);
		this.dataSourceSchema = outputDataSourceCollectionSchema;
		this.displayedColumns = this.dataSourceSchema.map(col => col.key);
		this.displayedColumns.unshift('datasetName');
		this.loadingOperationalOutputDataCollection = false;
		this.triggerLoadData();
	}

  	private triggerLoadData(){
		if (this.isNewRow) {
			this.removeRow();
		}
		const filteredData = this.generateChartDataService.getFilteredData(
		this.selectedType == 'input' ? this.inputDataCollection : this.outputDataCollection,
		this.domainId,
		this.subDomainId,
		this.siteId,
		this.startDate,
		this.endDate,
		this.selectedTimeline);
		
		this.dataSource = new MatTableDataSource(filteredData);
		this.dataSource.paginator = this.paginator;
		this.loadingOperationalInputDataCollection = false;
  	}

	public onSaveOrAddRow(): void {
		if (!this.loadingOperationalInputDataCollection) {
			this.isNewRow ? this.addOperationalData() : this.initiateOperationalDataCollection();
		}
	}

	public removeRow(){ 
		this.dataSource.data.shift();
		this.dataSource.data = this.dataSource.data;
		this.isNewRow = false;
	}
	
	public applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.paginator = this.paginator;
		this.paginator.firstPage();
	}
}