export interface DrillAndBlastCostsParams {
	id?: number;
	tonsPerHour: number;
	tonsPerHourGoal: number;
	finesPercent: number;
	finesPercentGoal: number;
	oversizePercent: number;
	oversizePercentGoal: number;
	explosivesCostPerTon: number;
	explosivesCostPerTonGoal: number;
	drillingCostPerTon: number;
	drillingCostPerTonGoal: number;
	drillAndBlastCostPerTon: number;
	drillAndBlastCostPerTonGoal: number;
	secondaryBreakageCost: number;
	secondaryBreakageCostGoal: number;
	recordedAt: string;
	datasetName?: string;
	domainId?: number;
	subdomainId?: number;
	siteId?: number;
	apiV2DatasetId?: number;
	siteName?: string;
}

export interface DataSourceSchema {
    key: string;
    type: string;
    label: string;
}

export const dataSourceCollectionSchema: DataSourceSchema[] = [
    { key: 'tonsPerHour', type: 'number', label: 'Tons Per Hour' },
    { key: 'tonsPerHourGoal', type: 'number', label: 'Tons Per Hour Goal' },
    { key: 'finesPercent', type: 'number', label: 'Fines Percent' },
    { key: 'finesPercentGoal', type: 'number', label: 'Fines Percent Goal' },
    { key: 'oversizePercent', type: 'number', label: 'Oversize Percent' },
    { key: 'oversizePercentGoal', type: 'number', label: 'Oversize Percent Goal' },
    { key: 'explosivesCostPerTon', type: 'number', label: 'Explosives Cost Per Ton' },
    { key: 'explosivesCostPerTonGoal', type: 'number', label: 'Explosives Cost Per Ton Goal' },
    { key: 'drillingCostPerTon', type: 'number', label: 'Drilling Cost Per Ton' },
    { key: 'drillingCostPerTonGoal', type: 'number', label: 'Drilling Cost Per Ton Goal' },
    { key: 'drillAndBlastCostPerTon', type: 'number', label: 'Drill And Blast Cost Per Ton' },
    { key: 'drillAndBlastCostPerTonGoal', type: 'number', label: 'Drill And Blast Cost Per Ton Goal' },
    { key: 'secondaryBreakageCost', type: 'number', label: 'Secondary Breakage Cost' },
    { key: 'secondaryBreakageCostGoal', type: 'number', label: 'Secondary Breakage Cost Goal' },
    { key: 'recordedAt', type: 'date', label: 'Recorded At' }
]