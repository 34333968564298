import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { DataSourceSchema, DrillShiftDetails, drillShiftDetailsSourceCollectionSchema } from '@app/models/data-collection.model';
import { User } from '@app/models/user.model';
import { combineLatest, debounceTime, Subject, Subscription } from 'rxjs';

@Component({
	selector: 'app-drill-shift-details-collection-view',
	templateUrl: './drill-shift-details-collection-view.component.html',
	styleUrls: ['./drill-shift-details-collection-view.component.scss']
})
export class DrillShiftDetailsCollectionViewComponent implements OnInit{
	@ViewChild(MatPaginator) paginator: MatPaginator;

	private user: User;
	private originalDataSourceCollectionResponse;
	private dataSourceCollectionResponse: DrillShiftDetails[] = [];
	public dataSourceSchema: DataSourceSchema[] = drillShiftDetailsSourceCollectionSchema;
	public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
	public dataSource: MatTableDataSource<DrillShiftDetails>;
	public loadingDrillShiftDetails: boolean;
	public currentDrillShiftDetail: DrillShiftDetails;
	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string;
	private subscriptions: Subscription = new Subscription();
	public siteDatasetLists: {id: number, name: string}[];
	private inputSubject: Subject<{id: number, key: string, element: {}}> = new Subject();

	constructor(
		private snackBar: MatSnackBar,
		public readonly userService: UserService,
		private readonly siteService: SiteService,
		private generateChartDataService: GenerateChartDataService
	) {
		const combinedSub = combineLatest([
			siteService.selectedTimeline$,
			siteService.startDate$,
			siteService.endDate$,
			siteService.subDomainId$,
			siteService.siteId$
		])
		.subscribe(ids => {
			const [selectedTimeline, startDate, endDate, subDomainId, siteId] = ids;
			this.selectedTimeline = selectedTimeline;
			this.startDate = startDate;
			this.endDate = endDate;
			this.subdomainId = subDomainId;
			this.siteId = siteId;
			this.triggerLoadData(this.dataSourceCollectionResponse);
		});
		this.subscriptions.add(combinedSub);

		this.subscriptions.add(this.inputSubject.pipe(debounceTime(500)).subscribe(value => {
			this.updateInputField(value);
		}));
	}

	ngOnInit(): void {
		this.displayedColumns.unshift('datasetName');
		this.user = this.userService.user;
		this.subscriptions.add(this.siteService.domainId$.subscribe((domainId:number)=>{
			this.domainId = domainId;
			if (this.domainId) {
				this.fetchDrillShiftDetailsCollectionView();
			}
		}));

		this.subscriptions.add(backend.loading$.subscribe((loading: boolean)=>
			this.loadingDrillShiftDetails = loading
		));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private async fetchDrillShiftDetailsCollectionView() {
		this.loadingDrillShiftDetails = true;
		const response = await backend.getDrillShiftDetailsCollectionView(this.domainId, this.selectedTimeline, this.startDate, this.endDate, this.subdomainId, this.siteId);
		backend.dataCollection$.next(response);
		let data = [];
		if (response) {
			for (let key in response) {
			  response[key].forEach((el, index)=> {
			  	el.showRow = index == 0;
			  	el.datasetName = key;
			  	const feetPerHour = this.calculateFeetPerHour(el);
			  	el.feetPerHour = feetPerHour;
			  })
			  data.push(...response[key])
			}
			this.originalDataSourceCollectionResponse = response;
			this.dataSourceCollectionResponse = data;
			this.triggerLoadData(this.dataSourceCollectionResponse);
		} else {
			this.dataSourceCollectionResponse = data;
		}
		this.loadingDrillShiftDetails = false;
	}

	public onFieldInputClick(key:string) {
		if (key === 'totalManHours' || key === 'totalDailyFootage') {
			this.snackBar.open('This field is not editable', 'Close', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3000 });
			return;
		}
	}	

	async onFieldInputChange(element, key: string, value: string|number|Date) {
		if (!element.id) {
			const totalManHours =this.calculateTotalManHours(element);
			element.totalManHours = totalManHours;
			return;
		}
		
		if (key && value) {
			if(+value < 0) {
				this.snackBar.open('Negative values are not allowed', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});
			}
			else  {
				const updatedData = {
					id: element.id,
					key:key,
					element: element
				}
				this.inputSubject.next(updatedData);
			}
		}
	}

	private async updateInputField(updatedData) {
		const response = await backend.updateDrillShiftDetailsCollectionField(updatedData.element.id, updatedData.key, updatedData.element[updatedData.key]);
		if(response?.data) {
			const feetPerHour = this.calculateFeetPerHour(response.data);
			updatedData.element.totalManHours = response.data.totalManHours;
			updatedData.element.feetPerHour = feetPerHour;
		}
		if (response?.message) {
			this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		}
	}
	
	public async initiateDrillShiftDetails() {
		if (this.currentDrillShiftDetail) {
			this.snackBar.open('Please fill the current row Drill Shift Detail', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});

			return;
		}

		if (!this.siteId) {
			this.snackBar.open('Please select Site prior adding Drill Shift Detail', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		this.loadingDrillShiftDetails = true;

		this.siteDatasetLists = await backend.fetchSiteDatasetsList(this.siteId);

		this.currentDrillShiftDetail = {
			travelTimeToAndFromSite: undefined,
			drillingAndTrammingHours: undefined,
			equipmentDowntime: undefined,
			siteStandby: undefined,
			totalManHours: undefined,
			totalDailyFootage: undefined,
			feetPerHour: undefined,
			bitsConsumed: undefined,
			steelConsumed: undefined,
			fuelConsumed: undefined,
			totalMaintenanceTime: undefined,
			patternLaidOutBy: '',
			userId: this.user.id,
			comment: '',
			recordedAt: '',
			datasetName: '',
			apiV2DatasetId: undefined,
			siteId: this.siteId,
			domainId: this.domainId,
			subdomainId: this.subdomainId
		};

		this.dataSourceCollectionResponse.push(this.currentDrillShiftDetail);
		this.dataSource = new MatTableDataSource(this.dataSourceCollectionResponse);
		this.loadingDrillShiftDetails = false;
	}

	private calculateTotalManHours(element: DrillShiftDetails) {
		const travelTime = +element.travelTimeToAndFromSite || 0;
		const drillingTime = +element.drillingAndTrammingHours || 0;
		const standbyTime = +element.siteStandby || 0;
		const maintenanceTime = +element.totalMaintenanceTime || 0;
	
		const totalManHours = travelTime + drillingTime + standbyTime + maintenanceTime;
	
		return totalManHours.toFixed(2);
	}

	private calculateFeetPerHour(element: DrillShiftDetails) {
		const totalManHours = +element.totalManHours;
		const totalDailyFootage = +element.totalDailyFootage;
		const feetPerHour = totalDailyFootage / totalManHours;

		return feetPerHour.toFixed(2);
	}
	
	public onDatasetSelect(event) {
		const value = event.value;

		this.currentDrillShiftDetail.datasetName = value.name;
		this.currentDrillShiftDetail.apiV2DatasetId = value.id;
	}

	async submitDrillShiftDetails() {
		this.calculateTotalManHours(this.currentDrillShiftDetail);
		const response = await backend.createDrillShiftDetails(this.currentDrillShiftDetail);

		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
			this.currentDrillShiftDetail.id = response.id;
			this.dataSourceCollectionResponse = [...this.dataSourceCollectionResponse];

			this.currentDrillShiftDetail = undefined;
			this.fetchDrillShiftDetailsCollectionView();
		}
	}

	public getRowSpan(element, idx) {
		const data = this.originalDataSourceCollectionResponse[element.datasetName] as DrillShiftDetails[];

		return element.showRow ? data.length : 0
	}

	
	public exportToCSV() {
		const columnKeys = this.displayedColumns;
		const columnOrder = ['Project', ...this.dataSourceSchema.map(item => item.label)]; 
		const dataSource = this.dataSource.data;
		if (!dataSource) return;
	
		let csvContent = columnOrder.join(',') + '\n'; 
		let prevProject = null;
	
		dataSource.forEach((item, index) => {
			const project = item['datasetName'];
			const rowData = columnKeys.map(key => {
				switch (key) {
					case 'comment':
						return `"${(item[key] || '').replace(/\n+/g, ' ').replace(/"/g, '""')}"`;
					case 'datasetName':
						return (project !== prevProject || index === 0) ? project : '';
					default:
						return item[key] || '';
				}
			});			
			csvContent += rowData.join(',') + '\n';
			prevProject = project;
		});
	
		const blob = new Blob([csvContent], { type: 'text/csv' });
	
		const anchor = document.createElement('a');
		anchor.href = window.URL.createObjectURL(blob);
		anchor.download = 'data.csv';
		anchor.click();
	}
	
	private triggerLoadData(originalData){
		const filteredData = this.generateChartDataService.getFilteredData(
			originalData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		);
		this.dataSource = new MatTableDataSource(filteredData);
		this.dataSource.paginator = this.paginator;
	}
}
