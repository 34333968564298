import storageLocal from "@utilities/storage-local";
import { getPromise } from "./request-promise";

interface UserResponse{
	id:number;
	email:string;
	firstName:string;
	lastName:string;
	name:string;
	measurementUnit:'imperial'|'metric';
	industry:string;
	address:string;
	phone:string;
	companyAddress:string;
	companyName:string;
	avatarUrl:string;
	blasterSignatureUrl:string;
	receiveProjectEmails:boolean;
	monthlyProjectLimit:number;
	roundOff:boolean;
	isTrialUser:boolean;
	blasterLicenses:[],
	isSuperAdmin:boolean;
	designerName:string|null;
	designerLogoUrl:string|null;
	customerName:string|null;
	customerLogoUrl:string|null;
	authenticationToken:string;
}

export interface User{
	id:number;
	email:string;
	firstName:string;
	lastName:string;
	name:string;
	measurementUnit:'imperial'|'metric';
	industry:string;
	address:string;
	phone:string;
	companyAddress:string;
	companyName:string;
	avatarUrl:string;
	blasterSignatureUrl:string;
	receiveProjectEmails:boolean;
	monthlyProjectLimit:number;
	isTrialUser:boolean;
	blasterLicenses:[],
	isSuperAdmin:boolean;
	designerName:string|null;
	designerLogoUrl:string|null;
	customerName:string|null;
	customerLogoUrl:string|null;
	authenticationToken:string;
}

export async function userAuthenticateToken(
	email:string,
	token:string,
):Promise<null|User>{
	if (!email || !token)
		return null;

	const r=await getPromise("api/v1/users/me",undefined,{
		ignoreErrors: [403],
	});
	if(!r)
		return null;

	const user=r;
	storageLocal.set("email", user.email);
	storageLocal.set("authenticationToken",user.authenticationToken);

	return user;
}
