import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { DataSourceSchema, DrillData, drillDataSourceCollectionSchema, mockDataCollection } from '@app/models/data-collection.model';
import { UnitConversionPipe, UnitDisplayPipe } from '@app/pipes';
import { combineLatest, debounceTime, Subject, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-drill-data-collection-view',
	templateUrl: './drill-data-collection-view.component.html',
	styleUrls: ['./drill-data-collection-view.component.scss'],
})
export class DrillDataCollectionViewComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;
	@ViewChild('fileInput') fileInput: ElementRef;
	private selectedTimeline: string;
	private startDate: string;
	private endDate: string;
	private subDomainId: number;
	private siteId: number;
	private inputSubject: Subject<{apiV2DatasetId: number, key: string, value: string}> = new Subject();
	private dataSourceCollectionResponse: DrillData[] = [];
	private subscriptions: Subscription = new Subscription();

	
	public isFileUploaded:boolean = false;
	public newRowData: DrillData;
	public isNewRow: boolean = false;
	public domainId:number;
	public datasetNames: { blast: string, apiV2DatasetId: number }[] = [];
	public selectedDataset: { blast: string, apiV2DatasetId: number };
	public dataSourceSchema: DataSourceSchema[] = drillDataSourceCollectionSchema;
	public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
	public dataSource: MatTableDataSource<DrillData>;
	public userUnitMeasurement: string;
	public loadingDrillData: boolean;

	public changeMeasurementArray = ['averageHoleDiameter','averageBenchHeight','averageHoleLength','totalDrillLength',
	'totalDrillDepth','averagePatternBurden','averageSpacing','averageSubdrill','shotVolume','powderFactorVolume','powderFactorWeight'];

	constructor(
		private snackBar: MatSnackBar,
		private readonly userService: UserService,
		private readonly siteService: SiteService,
		private generateChartDataService: GenerateChartDataService
	) {
		const combinedSub = combineLatest([
			siteService.selectedTimeline$,
			siteService.startDate$,
			siteService.endDate$,
			siteService.subDomainId$,
			siteService.siteId$
		])
		.subscribe(ids => {
			const [selectedTimeline, startDate, endDate, subDomainId, siteId] = ids;
			this.selectedTimeline = selectedTimeline;
			this.startDate = startDate;
			this.endDate = endDate;
			this.subDomainId = subDomainId;
			this.siteId = siteId;

			this.triggerLoadData(this.dataSourceCollectionResponse);
		});
		this.subscriptions.add(combinedSub);

		this.subscriptions.add(this.inputSubject.pipe(debounceTime(500)).subscribe(value => {
			this.updateInputField(value);
		}));
	}

	ngOnInit(): void {
		this.displayedColumns.unshift(...['datasetName','blastReportUrl']);

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe((measurement: string) => {
			this.userUnitMeasurement = measurement;
		}));

		this.subscriptions.add(this.siteService.domainId$.subscribe((domainId:number)=>{
			this.domainId = domainId;
			if (this.domainId) {
				this.fetchDrillDataCollectionView();
			}
		}));
	}

	ngAfterViewInit() {
		if (this.dataSource) {
			this.dataSource.paginator = this.paginator;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.paginator = this.paginator;
		this.paginator.firstPage();
	}

	convertValue(value: number, conversion: string): number {
		let convertedValue = value;
		if (conversion === 'm2ft') {
			convertedValue = value * 3.28084;
		} else if (conversion === 'ft2m') {
			convertedValue = value * 0.3048;
		}

		return parseFloat(convertedValue.toFixed(2));
	}

	private async fetchDrillDataCollectionView() {
		this.loadingDrillData = true;
		const response = await backend.getDrillDataCollectionView(this.domainId, this.selectedTimeline, this.startDate, this.endDate, this.subDomainId, this.siteId, this.isFileUploaded);
		backend.dataCollection$.next(response)

		if (response) {
			this.dataSourceCollectionResponse = response.map((item: DrillData) => ({ ...item, isRecordExist: false }));
			this.datasetNames = response.map((res: DrillData) => ({ apiV2DatasetId: res.apiV2DatasetId, blast: res.blast }));
			this.triggerLoadData(this.dataSourceCollectionResponse);
		} else {
			this.dataSourceCollectionResponse = response;
		}
		this.loadingDrillData = false;
	}

	onFieldInputClick(event: Event, col: DataSourceSchema, element: DrillData): void {
		const target = event.target as HTMLInputElement;
		if (!element.apiV2DatasetId) {
		  this.newRowData = element;
		}
		target.readOnly = col.readonly && !!element.apiV2DatasetId;
	}

	async onFieldInputChange(element:DrillData, key: string, value: any, valueUnit: string, type: string)
	{
		if (!element.apiV2DatasetId) return;
		if (key && value) {
			let tranformedValue: any =  value;
			if (type === 'number') {
				tranformedValue = (new UnitConversionPipe()).transform(Number(tranformedValue), this.userUnitMeasurement, valueUnit, true);
			}
			if (!this.isNewRow) {
				const updatedData = {
					apiV2DatasetId: element.apiV2DatasetId,
					key:key,
					value: tranformedValue
				}
				this.inputSubject.next(updatedData);
			}
			element[key] = type === 'number' ? Number(tranformedValue) : tranformedValue;
		}
	}

	private async updateInputField(updatedData) {
		const response = await backend.updateDrillDataCollectionField(updatedData.apiV2DatasetId, updatedData.key, updatedData.value);
		if (response?.message) {
			this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		}
	}

	public onDatasetSelect(event){
		const value = event.value;
		this.newRowData.blast = value.blast;
		this.newRowData.apiV2DatasetId =  value.apiV2DatasetId;
	}

	downloadToExcel(data: string) {
		const columnOrder: any = [{label: 'Project', key: 'datasetName'},{label: 'Blast Report URL', key: 'blastReportUrl'}, ...this.dataSourceSchema];
		const dataSource = data === "exportExcel" ? this.dataSource?.data  : mockDataCollection;
		if(!dataSource) return;
		const modifiedDataSource = dataSource.map(item => {
			const result = {};
			columnOrder.forEach(schema => {
			const key = schema.key; 
			const value = item[key];
			let newKey = schema.label;
			
			if (schema.valueUnit) {
				newKey = `${(new UnitDisplayPipe()).transform(schema.label, this.userUnitMeasurement, schema.valueUnit)}`;
			}
		
			if (value !== null && value !== undefined && schema.valueUnit) {
				result[newKey] = (new UnitConversionPipe()).transform(Number(value), this.userUnitMeasurement, schema.valueUnit);
			} else {
				result[newKey] = value;
			}
			});
		
			return result;
		});
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedDataSource);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();

		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		// Save to file
		const fileName = data === "exportData" ?  'SheetJS.xlsx' : 'Sample.xlsx';
		XLSX.writeFile(wb, fileName);
	}

	importAsExcel() {
		this.fileInput.nativeElement.click();
	  }

	  onFileChange(event: Event): void {
		const file = (event.target as HTMLInputElement).files[0];
		this.uploadFile(file);
	  }

	  async uploadFile(file: File) {
		const response = await backend.uploadFile(file)
		if(response) {
		  this.fileInput.nativeElement.value = null;

		  if (response?.message) {
			this.snackBar.open(response.message, ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		  }

		  this.isFileUploaded = true;
		  this.fetchDrillDataCollectionView();
		}
		else {
			this.snackBar.open('Error importing data: Unsupported file format', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		}
	  }

	  onSaveOrAddRow(): void {
		this.isNewRow ? this.saveRow() : this.addRow() ;
	  }

	  addRow(): void {
		this.isNewRow = true;
		let newRowData: any = {};
		this.dataSourceSchema.forEach((col) => {
			newRowData[col.key] = '';
		});
		this.paginator.firstPage();

		const currentData = this.dataSource.data.slice();
		currentData.splice(0, 0, newRowData);

		this.newRowData = newRowData;
		this.dataSource.data = currentData;
		this.scrollToFirstIndex();
	}

	scrollToFirstIndex(): void {
		let elem = this.rows.first;
		elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}

	  removeRow(): void {
		this.isNewRow = false;
		const index = this.dataSource.data.findIndex(
		  (row) => row === this.newRowData
		);
		if (index !== -1) {
		  this.dataSource.data.splice(index, 1);
		  this.dataSource._updateChangeSubscription();
		}
	  }

	  async saveRow() {
		if (this.newRowData) {
			this.isNewRow = false;
			this.newRowData.blast = this.selectedDataset.blast;
			this.newRowData.apiV2DatasetId = this.selectedDataset.apiV2DatasetId;

			const response = await backend.addDataCollectionField(this.newRowData, this.domainId);
			if (response?.message) {
				this.snackBar.open(response.message, '', {
					horizontalPosition: 'right',
					verticalPosition: 'top',
					duration: 3 * 1000,
				});
				this.newRowData.isRecordExist = true;
				this.selectedDataset = null;
			}
		}
	}

	private triggerLoadData(originalData){
		const filteredData = this.generateChartDataService.getFilteredData(
			originalData,
			this.domainId,
			this.subDomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		);
		this.dataSource = new MatTableDataSource(filteredData);
		this.dataSource.paginator = this.paginator;
	}
}
