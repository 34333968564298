import { Component } from '@angular/core';

@Component({
    selector: 'app-data-collection-view',
    templateUrl: './data-collection-view.component.html',
    styleUrls: ['./data-collection-view.component.scss']
})

export class DataCollectionViewComponent  {
    constructor(
    ) {
    }
}
