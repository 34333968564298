<h2 mat-dialog-title class="dialog-title">Measurement Settings</h2>
<div class="content-container">
    <div class="form-row">
        <mat-form-field class="measurement-unit-field">
            <mat-label>Measurement Unit</mat-label>
            <mat-select matNativeControl [(value)]="userUnitMeasurement">
                <mat-option value="metric">Metric</mat-option>
                <mat-option value="imperial">Imperial</mat-option>
            </mat-select>
            <mat-hint class="measurement-unit-hint">Controls what units the interface uses when displaying measurements</mat-hint>
        </mat-form-field>
    </div>
    <!-- <div>
        <mat-slide-toggle labelPosition="before" [(ngModel)]="roundDrillDesign$">Round Off Values</mat-slide-toggle>
    </div> -->
    <div class="dialog-actions">
        <button mat-button [mat-dialog-close]="null" class="secondary">Cancel</button>
        <button mat-button color="primary" class="save-button" (click)="saveMeasurementSettings()">Save</button>
    </div>
</div>
