import { Component } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/app-state/user.service';

@Component({
	selector: 'unit-settings-dialog',
	templateUrl: './unit-settings-dialog.component.html',
	styleUrls: ['./unit-settings-dialog.component.scss'],
})

export class UnitSettingsDialog{
	public static readonly config:MatDialogConfig={
		disableClose: true,
	};

	public userUnitMeasurement = this.userService.user.measurementUnit;

	public constructor(
		private dialog:MatDialogRef<UnitSettingsDialog>,
		public readonly userService:UserService,
	){}


	public saveMeasurementSettings(){
		this.userService.user.measurementUnit=this.userUnitMeasurement;
		this.userService.saveUser();
		this.dialog.close();
	}
}
