import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from '@environments/environment';
import storageLocal from '@app/utilities/storage-local';

export const userGuard: CanActivateFn = async (route, state) => {
	const userService = inject(UserService);
	const router = inject(Router);

	// const searchUrl = window.location.search;
	// if (searchUrl) {
	// 	const searchParams = new URLSearchParams(searchUrl);
	// 	const email = searchParams.get('email');
	// 	const token = searchParams.get('token');

	// 	if (email && token) {
	// 		localStorage.setItem("email", email);
	// 		localStorage.setItem("authenticationToken", token);

	// 		if (await userService.loggedIn())
	// 			return true;
	// 	} else {
	// 		window.location.href = environment.accountsLoginRedirectUrl();

	// 		return false;
	// 	}
	// } else {
	// 	const email = localStorage.getItem('email');
	// 	const token = localStorage.getItem('authenticationToken');

	// 	if (email && token) {
	// 		localStorage.setItem("email", email);
	// 		localStorage.setItem("authenticationToken", token);
	// 	}

	// 	const isUserLoggedIn = await userService.loggedIn();
	// 	if (isUserLoggedIn)
	// 		return true;

	// }

	if(await userService.loggedIn())
			return true;

	return router.createUrlTree(['/login']);

	// return router.navigateByUrl('/login');
};
