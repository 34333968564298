<div class="main-container">
	<div class="container">
		<ng-content select=".filters"></ng-content>

		<div class="right">
			<div class="user-menu-button" [matMenuTriggerFor]="userMenu">
				<avatar></avatar>

				<div class="user-menu-options">
					<span>{{user?.firstName}} {{user?.lastName}}</span>
					<mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
				</div>
			</div>
		</div>
	</div>
</div>

<mat-menu #userMenu="matMenu">
	<button mat-menu-item matTooltip="Unit Settings" (click)="unitSettings()">
		<mat-icon>square_foot</mat-icon>
		Unit Conversion
	</button>

	<button mat-menu-item [matMenuTriggerFor]="helpMenu" class="help">
		<mat-icon>help_outline</mat-icon>
		Help
	</button>

	<button mat-menu-item >
		<mat-icon>notifications_none</mat-icon>
		Notifications
	</button>	

	<button mat-menu-item (click)="logout()">
		<mat-icon>logout</mat-icon>
		Logout
	</button>
</mat-menu>

<mat-menu #helpMenu="matMenu">
	<a mat-menu-item href="javascript:$zopim.livechat.window.show();">
		<mat-icon>chat</mat-icon>
		Contact Support
	</a>
	<a mat-menu-item href="https://help.strayos.com/" target="_blank">
		<mat-icon>info</mat-icon>
		Help Center
	</a>
</mat-menu>
