import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import * as backend from '@app/backend';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { dataSourceCollectionSchema, DataSourceSchema, DrillAndBlastCostsParams } from '@app/models/drill-and-blast-costs.model';
import * as Highcharts from 'highcharts/highstock';
import { combineLatest, debounceTime, Subject, Subscription } from 'rxjs';
import { DrillAndBlastCostService } from './service';

@Component({
	selector: 'app-drill-and-blast-costs',
	templateUrl: './drill-and-blast-costs.component.html',
	styleUrls: ['./drill-and-blast-costs.component.scss']
})
export class DrillAndBlastCostsComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

	public selectedMatTabIndex: number = 0;
	public loadingDrillAndBlastCosts: boolean;
	public currentDrillAndBlastCost: DrillAndBlastCostsParams;
	public drillAndBlastCosts: DrillAndBlastCostsParams[] = [];
	public dataSourceSchema: DataSourceSchema[] = dataSourceCollectionSchema;
	public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
	public dataSource: MatTableDataSource<DrillAndBlastCostsParams>;

	private tonsPerHourGraph:Highcharts.Chart;
	@ViewChild('tonsPerHourGraph') tonsPerHourGraphDiv: ElementRef<HTMLDivElement>;
	private finesPercentGraph:Highcharts.Chart;
	@ViewChild('finesPercentGraph') finesPercentGraphDiv: ElementRef<HTMLDivElement>;
	private oversizePercentGraph:Highcharts.Chart;
	@ViewChild('oversizePercentGraph') oversizePercentGraphDiv: ElementRef<HTMLDivElement>;
	private explosivesCostPerTonGraph:Highcharts.Chart;
	@ViewChild('explosivesCostPerTonGraph') explosivesCostPerTonGraphDiv: ElementRef<HTMLDivElement>;
	private drillingCostPerTonGraph:Highcharts.Chart;
	@ViewChild('drillingCostPerTonGraph') drillingCostPerTonGraphDiv: ElementRef<HTMLDivElement>;
	private drillAndBlastCostPerTonGraph:Highcharts.Chart;
	@ViewChild('drillAndBlastCostPerTonGraph') drillAndBlastCostPerTonGraphDiv: ElementRef<HTMLDivElement>;
	private secondaryBreakageCostGraph:Highcharts.Chart;
	@ViewChild('secondaryBreakageCostGraph') secondaryBreakageCostGraphDiv: ElementRef<HTMLDivElement>;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string;
	private subscriptions: Subscription = new Subscription();
	public siteDatasetLists: {id: number, name: string}[];
	private inputSubject: Subject<DrillAndBlastCostsParams> = new Subject();
	private selectedSiteName: string;
	public isNewRow: boolean = false;

	constructor(
		private snackBar: MatSnackBar,
		private drillAndBlastCostService: DrillAndBlastCostService,
		private readonly siteService: SiteService,
		private generateChartDataService: GenerateChartDataService
	) {
		const combinedSub = combineLatest([
			siteService.selectedTimeline$,
			siteService.startDate$,
			siteService.endDate$,
			siteService.subDomainId$,
			siteService.siteId$
		])
		.pipe(debounceTime(100))
		.subscribe(ids => {			
			const [selectedTimeline, startDate, endDate, subDomainId, siteId] = ids;
			this.selectedTimeline = selectedTimeline;
			this.startDate = startDate;
			this.endDate = endDate;
			this.subdomainId = subDomainId;
			this.siteId = siteId;
			this.triggerLoadData(this.drillAndBlastCosts);
		});
		this.subscriptions.add(combinedSub);
		
		this.subscriptions.add(this.inputSubject.pipe(debounceTime(500)).subscribe(value => {
			this.updateInputField(value);
		}));
	}

	ngOnInit() {
		// Read only Fields
		this.displayedColumns.unshift('datasetName');

		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.constructChartsAndPopulateTableView(this.domainId);
			}
		}));

		this.subscriptions.add(backend.loadingDrillAndBlastCostsData$.subscribe((loading)=>{
			this.loadingDrillAndBlastCosts = loading;
		}));
		
		this.subscriptions.add(backend.drillAndBlastCostsData$.subscribe((response)=>{
			this.drillAndBlastCosts = response
		}));

		this.subscriptions.add(this.siteService.seletedSiteName$.subscribe((siteName)=>{
			if (this.isNewRow) {
				this.drillAndBlastCosts.shift();
				this.currentDrillAndBlastCost = null;
				this.isNewRow = false;
			}
			this.selectedSiteName = siteName;
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	
	private constructChartsAndPopulateTableView(domainId: number) {
		this.subscriptions.add(backend.getCacheDrillAndBlastCostsByDomain(domainId).subscribe(async (response)=>{
			if (response) {
				this.drillAndBlastCosts = await response;
				this.triggerLoadData(this.drillAndBlastCosts);
			}
		}));
	}

	private constructDrillAndBlastCostCharts(drillAndBlastCosts:DrillAndBlastCostsParams[]) {
		this.drillAndBlastCostService.constructDrillAndBlastCostCharts(drillAndBlastCosts);

		this.tonsPerHourGraph = Highcharts.chart(this.tonsPerHourGraphDiv.nativeElement, this.drillAndBlastCostService.tonsPerHourChartOptions);

		this.finesPercentGraph = Highcharts.chart(this.finesPercentGraphDiv.nativeElement, this.drillAndBlastCostService.finesPercentChartOptions);

		this.oversizePercentGraph = Highcharts.chart(this.oversizePercentGraphDiv.nativeElement, this.drillAndBlastCostService.oversizePercentChartOptions);

		this.explosivesCostPerTonGraph = Highcharts.chart(this.explosivesCostPerTonGraphDiv.nativeElement, this.drillAndBlastCostService.explosivesCostPerTonChartOptions);

		this.drillingCostPerTonGraph = Highcharts.chart(this.drillingCostPerTonGraphDiv.nativeElement, this.drillAndBlastCostService.drillingCostPerTonChartOptions);

		this.drillAndBlastCostPerTonGraph = Highcharts.chart(this.drillAndBlastCostPerTonGraphDiv.nativeElement, this.drillAndBlastCostService.drillAndBlastCostPerTonChartOptions);

		this.secondaryBreakageCostGraph = Highcharts.chart(this.secondaryBreakageCostGraphDiv.nativeElement, this.drillAndBlastCostService.secondaryBreakageCostChartOptions);
	}

	onFieldInputClick(event) {
		const target = event.target;
		target.readOnly = false;
	}

	async onFieldInputChange(element, key: string, value: string|number|Date) {
		if (!element.id) return;

		if (key && value) {
			const drillAndBlastCost = this.drillAndBlastCosts.find(cost => cost.id == element.id);
			if (drillAndBlastCost) {
				drillAndBlastCost[key] = value;
				this.inputSubject.next(drillAndBlastCost);
			}
		}
	}

	private async updateInputField(updatedData: DrillAndBlastCostsParams) {
		const response = await backend.updateDrillAndBlastCosts(updatedData);
		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		}
	}

	public onSelectedMatTabChange(event) {
		if (event.index == 0) {
			if (this.isNewRow) {
				this.drillAndBlastCosts.shift();
				this.currentDrillAndBlastCost = null;
				this.isNewRow = false;
			}
			this.triggerLoadData(this.drillAndBlastCosts);
		}
	}

	public async initiateDrillAndBlastCosts() {
		if (this.currentDrillAndBlastCost) {
			this.snackBar.open('Please fill the current row Drill And Blast Costs', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		if (!this.siteId) {
			this.snackBar.open('Please select Site prior adding Costs', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		if (this.selectedMatTabIndex === 0) {
			this.selectedMatTabIndex = 1; 				// Change into table view
		}
		this.loadingDrillAndBlastCosts = true;

		this.siteDatasetLists = await backend.fetchSiteDatasetsList(this.siteId);
		this.isNewRow = true;

		this.currentDrillAndBlastCost = {
			tonsPerHour: 0,
			tonsPerHourGoal: 0,
			finesPercent: 0,
			finesPercentGoal: 0,
			oversizePercent: 0,
			oversizePercentGoal: 0,
			explosivesCostPerTon: 0,
			explosivesCostPerTonGoal: 0,
			drillingCostPerTon: 0,
			drillingCostPerTonGoal: 0,
			drillAndBlastCostPerTon: 0,
			drillAndBlastCostPerTonGoal: 0,
			secondaryBreakageCost: 0,
			secondaryBreakageCostGoal: 0,
			recordedAt: new Date().toLocaleDateString(),
			datasetName: '',
			apiV2DatasetId: undefined,
			siteId: this.siteId,
			domainId: this.domainId,
			subdomainId: this.subdomainId,
			siteName: this.selectedSiteName
		};
		this.drillAndBlastCosts.unshift(this.currentDrillAndBlastCost);
		this.paginator.firstPage();
		this.scrollToFirstIndex();
		this.triggerLoadData(this.drillAndBlastCosts);
	}

	private scrollToFirstIndex(): void {
		let elem = this.rows.first;
		elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}

	public onDatasetSelect(event) {
		const value = event.value;

		const isCostFoundPerProject = this.drillAndBlastCosts.find(cost => cost.apiV2DatasetId == value.id);
		if (isCostFoundPerProject) {
			this.snackBar.open('A Project should have only one Cost. Please select other Project and add Cost.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
			event.source.value = undefined;

			return;
		}

		this.currentDrillAndBlastCost.datasetName = value.name;
		this.currentDrillAndBlastCost.apiV2DatasetId = value.id;
	}

	public async addDrillAndBlastCosts() {
		if (this.currentDrillAndBlastCost && !this.currentDrillAndBlastCost.apiV2DatasetId) {
			this.snackBar.open('Please select a Project and add Cost.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			return;
		}

		const response = await backend.createDrillAndBlastCosts(this.currentDrillAndBlastCost);
		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			if (response.id) {
				this.currentDrillAndBlastCost.id = response.id;
				this.drillAndBlastCosts = [...this.drillAndBlastCosts];

				this.drillAndBlastCostService.originalDrillAndBlastCosts.push(this.currentDrillAndBlastCost);
				this.isNewRow = false;
				this.currentDrillAndBlastCost = undefined;
			}
		}
	}

	triggerLoadData(originalData){
		this.loadingDrillAndBlastCosts = true;
		const filterData = this.generateChartDataService.getFilteredData(
			originalData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		)
		this.dataSource = new MatTableDataSource(filterData);
		
		// Ensure paginator is set
		if (!this.dataSource.paginator) {
			this.dataSource.paginator = this.paginator;
		}

		// Update chart and loading status
		if (filterData) {	
			this.constructDrillAndBlastCostCharts(filterData);
		}
		this.loadingDrillAndBlastCosts = false;
	}
}
