<div class="header-container">
    <button mat-raised-button color="primary" (click)="initiateDrillShiftDetails()">+ Drill Shift Details</button>
    <button *ngIf="currentDrillShiftDetail && !currentDrillShiftDetail.id" mat-raised-button color="primary" (click)="submitDrillShiftDetails()">Submit</button>
    <button mat-raised-button color="primary" (click)="exportToCSV()">Export to CSV</button>
</div>

<div class="content-container">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="datasetName">
            <th mat-header-cell *matHeaderCellDef>Project</th>
            <td mat-cell *matCellDef="let element; let i = index" [attr.rowspan]="getRowSpan(element, i)" [style.display]="!element.id || getRowSpan(element, i) > 0 ? '' : 'none'">
                <ng-container *ngIf="!element.id; else displayColumn">
                    <mat-select (selectionChange)="onDatasetSelect($event)" class="select-project">
                        <mat-option *ngFor="let dataset of siteDatasetLists" [value]="dataset">{{dataset.name}}</mat-option>
                    </mat-select>
                </ng-container>
            
                <ng-template #displayColumn>
                    <span class="select-project">{{element.datasetName}}</span>
                </ng-template>
            </td>            
        </ng-container>

        <ng-container [matColumnDef]="col.key" *ngFor="let col of dataSourceSchema">
            <th mat-header-cell *matHeaderCellDef>
                {{col.label}}
            </th>

            <td mat-cell *matCellDef="let element">
                <input [type]="col.type" 
                    matInput 
                    [(ngModel)]="element[col.key]" 
                    [readonly]="col.readonly"
                    (click)="onFieldInputClick(col.key)" 
                    (ngModelChange)="onFieldInputChange(element, col.key, $event)"  
                />
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator
        pageSize="30"
        [pageSizeOptions]="[30, 40]"
        hidePageSize="true"
    >
    </mat-paginator>
</div>

<div class="loading-container" *ngIf="loadingDrillShiftDetails">
    <mat-spinner matTooltip="Loading Drill Shift Details"></mat-spinner>
</div>
