import * as rxjs from 'rxjs';

export class BehaviorSubject<T> extends rxjs.BehaviorSubject<T>{
	public nextIfChanged(value:T){
		if(this.getValue()===value)
			return false;
		this.next(value);
		return true;
	}
}
