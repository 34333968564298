import { HttpParams } from '@angular/common/http';
import storageLocal from '@utilities/storage-local';
import { environment as e } from '../../environments/environment';

const backendUrl = e.apiUrl;
export const graphqlUrl = `${backendUrl}/graphql`;

export function fullUrl(relativeUrl: string) {
	if(relativeUrl.startsWith('http://') || relativeUrl.startsWith('https://'))
		return relativeUrl;
	relativeUrl=relativeUrl.replace(/^api\//, e.apiPrefix + '/');
	return backendUrl+'/'+relativeUrl;
}

function fixJSONProperties(data: any) {
	//the backend doesn't follow the right property naming scheme that it should so this function fixes that until we update the backend

	if (data instanceof Array) {
		for (let i = 0; i < data.length; ++i)
			data[i] = fixJSONProperties(data[i]);
		return data;
	}

	if (data instanceof Object) {
		for (let name in data) {
			let newName = name.replace(/_(.)/g, (p0, p1) => { return p1.toUpperCase(); });
			let value = data[name];
			if (value instanceof Object)
				value = fixJSONProperties(value);
			if (newName != name) {
				delete data[name];
				data[newName] = value;
			}
		}
	}

	return data;
}

export let notifyOnError:(message:string)=>void;
export function setNotifyOnError(func:(message:string)=>void){
	notifyOnError=func;
}

function setAuthentication(request:XMLHttpRequest){
	//this is the authorization used by most api functions
	//if we're logged we just add it automatically
	let email=storageLocal.get('email');
	let token=storageLocal.get('authenticationToken');
	const datasetShareKey=new URLSearchParams(window.location.search).get('share_key');

	if (email && token) {
		request.setRequestHeader('X-User-Email', email);
		request.setRequestHeader('X-User-Token', token);
	}

	if (datasetShareKey) {
		request.setRequestHeader('X-Shared-Access-Key', datasetShareKey);
	}
}

function makeSenseOfError(status:number, responseType:string, response:any){
	if(response && responseType==='json'){
		if(Array.isArray(response))
			return response[0];//backend returns error messages as arrays for some reason we undo that
		if(typeof(response?.error) === 'string')
			return response?.error;
		if(typeof(response?.errors) === 'string')
			return response?.errors;
		if(typeof(response)==='object' && response!==null){
			const errorMessages:string[]=[];
			for(const [key, value] of Object.entries(response)) {
				if(Array.isArray(value))
					errorMessages.push(`${key} ${value.join(',')}`);
				else if (typeof (value) === 'string')
					errorMessages.push(value);
			}
			if(errorMessages.length>0)
				return errorMessages.join(',');
		}
	}

	if(status==401)
		return 'unauthorized';
	if(status==404)
		return 'could not connect to server';
	if(status==408)
		return 'connection timed out';
	return 'unknown error';
}

export interface RequestOptions{
	responseType?:XMLHttpRequestResponseType;
	fixJSON?:boolean,
	onProgress?:(v:number)=>void;
	timeout?:number;
	ignoreErrors?:number[];
	leaveInitialValue?:boolean;
}

export interface RequestArgs<T> extends RequestOptions{
	url:string;
	method:'get'|'post'|'put'|'delete';
	body?:{}|FormData|Blob|null;
	success:(data:T)=>void,
	failure:(error:string)=>void,
}

export function xmlHttpRequest<T>(
	args:RequestArgs<T>,
) {
	args.body??=null;
	args.responseType??='json';
	args.fixJSON??=true;
	const url=fullUrl(args.url);
	const {method,responseType,fixJSON,success,failure,body}=args;

	let bodyStr:string|FormData|Blob='';
	let contentType=null;
	if((method==='post' || method==='put') && body){
		if(body instanceof FormData){
			bodyStr=body;
		}else if(body instanceof Blob){
			bodyStr=body;
			contentType=body.type;
		}else{
			bodyStr=JSON.stringify(body);
			contentType='application/json';
		}
	}

	const request=new XMLHttpRequest();
	request.open(method,url);
	request.responseType=responseType;

	if(args.timeout)
		request.timeout=args.timeout;

	if(url.startsWith(backendUrl))
		setAuthentication(request);

	if(contentType!==null)
		request.setRequestHeader('Content-Type',contentType);

	request.setRequestHeader('Accept', 'application/json, text/plain, */*');
	
	request.onload=()=>{
		let response=request.response;
		if(request.status==200 || request.status==201 || request.status==202){ // 202 is when process is still running
			if(responseType==='json' && fixJSON)
				response=fixJSONProperties(response);
			if(success)
				success(response);
		}else if(request.status==204){
			if(success)
				success(undefined);
		}else{
			const error=makeSenseOfError(request.status,request.responseType,response);

			if(
				(request.status==401 || request.status==404 || request.status===408 || request.status==500 || request.status==422)
				&& !args.ignoreErrors?.includes(request.status)
			)
				console.log(error +' Contact Strayos Support for assistance');//hard errors get unconditional notification
			if(failure)
				failure(error);
		}
	};
	request.onerror=()=>{
		const error='could not connect to server';
		if(failure)
			failure(error);
		else
			console.log(error);
	};

	if(args.onProgress){
		request.onprogress=ev=>{
			let percent=0;
			if(ev.lengthComputable){
				percent=ev.loaded/ev.total;
			}else{
				//sometimes it thinks it doesn't know the length but its in the headers lets find it ourselves
				//this doesn't really work it doesn't know the length because of compression, this size is its compressed size
				const headers=request.getAllResponseHeaders().toLowerCase();
				const matches=/content-length: ([0-9]+)/.exec(headers);
				if(matches){
					const total=+matches[1];
					percent=ev.loaded/total;
				}else{
					percent=0;//we can't determin percent
				}
			}
			args.onProgress(percent);
		};
	}
	request.send(bodyStr);
	return request;
}

export function query(query:{[key:string]:(string|number)}){
	const params=new HttpParams({fromObject: query});
	return `?${params.toString()}`;
}
