import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { ChartDataService, UserDomainInfo } from '@app/backend';
import { Domain } from '@app/models/domain.model';
import { Site } from '@app/models/site.model';
import { Subdomain } from '@app/models/subdomain.model';
import { User } from '@app/models/user.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	public user: User;
	public selectedItem: string = 'drillAndBlast';
	public options = ['1D', '1W', '1M', '3M', '6M', '1Y'];
	public selectedTimeline: string | null = null;
	public showStartDatePicker: boolean = false;
	public showEndDatePicker: boolean = false;
	public selectedStartDate: Date | null = null;
	public selectedEndDate: Date | null = null;
	formattedDate: string;
	public userDomainInfo: UserDomainInfo;

	public domains: Domain[];
	public filteredDomains: Observable<Domain[]>;
	public selectedDomainId: number;
	public domainControl = new FormControl<string | Domain>('');

	private subdomains: Subdomain[];
	public filteredSubdomains: Observable<Subdomain[]>;
	public selectedSubdomainId: number;
	public subdomainControl = new FormControl<string | Subdomain>('');

	private sites: Site[];
	public filteredSites: Observable<Site[]>;
	public selectedSiteId: number;
	public siteControl = new FormControl<string | Site>('');

	constructor(
		private readonly userService:UserService,
		private readonly siteService: SiteService,
		private readonly chartDataService: ChartDataService,
		private datePipe: DatePipe,
	) {
	}

	ngOnInit(): void {
		this.user = this.userService.user;
		this.userService.userDomainInfo$.subscribe(userDomainInfo => {
			if (userDomainInfo) {
				this.userDomainInfo = userDomainInfo;
				this.domains = this.userDomainInfo.domains;

				this.filteredDomains = this.domainControl.valueChanges.pipe(
					startWith(''),
					map(value => {
						const name = typeof value === 'string' ? value : value?.domainName;
						return name ? this.onDomainSearch(name as string) : this.domains?.slice();
					}),
				);
			}
		});


	}

	navigateToDomain() {
		window.location.href = '/';
	}

	displayDomainNameFn(domain: Domain) {
		return domain && domain.domainName ? domain.domainName : '';
	}

	onDomainSearch(domainName: string) {
		return this.domains.filter(domain => domain.domainName.toLowerCase().includes(domainName.toLowerCase()));
	}

	onDomainInput(event) {
		if (!event.target.value) {
			this.resetDomain();
			this.resetSubDomain();
			this.resetSite();
		}
	}

	onSubDomainInput(event) {
		if (!event.target.value) {
			this.resetSubDomain();
			this.resetSite();
		}
	}

	onSiteInput(event) {
		if (!event.target.value) {
			this.resetSite()
		}
	}

	resetDomain() {
		this.domainControl.setValue(null);
		this.siteService.selectedDrillMachine$.next(null);
		this.onDomainSelect();
	}

	resetSubDomain() {
		this.subdomainControl.setValue(null);
		this.chartDataService.allFielderDataset$.next(null)
		this.onSubdomainSelect();
	}

	resetSite() {
		this.siteControl.setValue(null);
		this.onSiteSelect();
	}

	onDomainSelect() {
		backend.drillAndBlastCostsData$.next(null);
		const domain = this.domainControl.value as Domain;

		this.selectedDomainId = domain?.id;
		this.siteService.domainId$.next(this.selectedDomainId);
		this.resetSubDomain();
		this.resetSite();
		this.subdomains = this.userDomainInfo.subdomains.filter(subdomain => subdomain.domainId == this.selectedDomainId);
		this.filteredSubdomains = this.subdomainControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.subdomainName;
				if (name) {
					return this.onSubdomainSearch(name as string)
				} else {
					this.selectedSubdomainId = undefined;
					return this.subdomains?.slice();
				}
			}),
		);
	}

	displaySubdomainNameFn(subdomain: Subdomain) {
		return subdomain && subdomain.subdomainName ? subdomain.subdomainName : '';
	}

	onSubdomainSearch(subdomainName: string) {
		return this.subdomains.filter(subdomain => subdomain.subdomainName.toLowerCase().includes(subdomainName.toLowerCase()));
	}

	async onSubdomainSelect () {
		const subdomain = this.subdomainControl.value as Subdomain;
		this.resetSite();
		this.selectedSubdomainId = subdomain?.id;
		this.siteService.subDomainId$.next(this.selectedSubdomainId);
		if(this.siteService.activeSideNav == 'fielder' && this.selectedSubdomainId) {
			this.chartDataService.getDrillMachines(this.selectedSubdomainId);
		}
		this.sites = this.selectedSubdomainId ? await backend.getSubDomainSite(this.selectedSubdomainId) : [];
		this.filteredSites = this.siteControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				if (name) {
					return this.onSiteSearch(name as string);
				} else {
					this.selectedSiteId = undefined;
					return this.sites?.slice();
				}
			}),
		);
	}

	displaySiteNameFn(site: Site) {
		return site && site.name ? site.name : '';
	}

	onSiteSearch(siteName: string) {
		return this.sites.filter(site => site.name.toLowerCase().includes(siteName.toLowerCase()));
	}

	onSiteSelect() {
		const site = this.siteControl.value as Site;
		this.selectedSiteId = site?.id;
		this.sites?.map((site)=>{
			if (site.id == this.selectedSiteId) {
				this.siteService.seletedSiteName$.next(site.name);
			}
		})
		this.siteService.siteId$.next(this.selectedSiteId)
	}

	onDateInput(event) {
		console.log('Date>>>',event);
	}

	updateSideNav(event) {
		this.siteService.activeSideNav$.next(event)
	}

	toggleDatePicker(picker: string) {
		if (picker === 'start') {
		  this.showStartDatePicker = !this.showStartDatePicker;
		  if (this.showStartDatePicker) {
			this.showEndDatePicker = false;
		  }
		} else if (picker === 'end') {
		  this.showEndDatePicker = !this.showEndDatePicker;
		  if (this.showEndDatePicker) {
			this.showStartDatePicker = false;
		  }
		}
	}
	
	onDateChange(event: MatDatepickerInputEvent<Date>, type: string) {
		let date = event.value;
		let formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
		this.selectedTimeline = null;
		this.siteService.selectedTimeline$.next(null);

		if (type === "startDate") {
			this.selectedStartDate = date;
			this.siteService.startDate$.next(formattedDate);
		} else {
			this.selectedEndDate = date;
			this.siteService.endDate$.next(formattedDate);
		}
		
	}

	toggleSelection(option: string) {

		this.selectedStartDate = null;
		this.selectedEndDate = null;

		this.siteService.startDate$.next(null);
		this.siteService.endDate$.next(null);

		this.selectedTimeline = this.selectedTimeline === option ? null : option;

		this.siteService.selectedTimeline$.next(this.selectedTimeline);
	 }

}
