import { Injectable } from '@angular/core';
import { DrillAndBlastCostsParams } from '@app/models/drill-and-blast-costs.model';
import * as Highcharts from 'highcharts/highstock';

@Injectable({
	providedIn: 'root'
})

export class DrillAndBlastCostService {
	public originalDrillAndBlastCosts: DrillAndBlastCostsParams[] = [];
	private filteredDrillAndBlastCosts: DrillAndBlastCostsParams[] = [];

	private siteNameAsXAxisLabels: string[] = [];

	public tonsPerHourChartOptions: Highcharts.Options;
	public finesPercentChartOptions: Highcharts.Options;
	public oversizePercentChartOptions: Highcharts.Options;
	public explosivesCostPerTonChartOptions: Highcharts.Options;
	public drillingCostPerTonChartOptions: Highcharts.Options;
	public drillAndBlastCostPerTonChartOptions: Highcharts.Options;
	public secondaryBreakageCostChartOptions: Highcharts.Options;

	constructor(
	) { }

	public constructDrillAndBlastCostCharts(drillAndBlastCosts: DrillAndBlastCostsParams[]) {
		this.filteredDrillAndBlastCosts = drillAndBlastCosts;


		this.tonsPerHourChartOptions = this.getChartOptions('tonsPerHour', 'tonsPerHourGoal', 'bar', 'Tons per Hour');
		this.finesPercentChartOptions = this.getChartOptions('finesPercent', 'finesPercentGoal', 'area', 'Fines Percent');
		this.oversizePercentChartOptions = this.getChartOptions('oversizePercent', 'oversizePercentGoal', 'area', 'Oversize Percent');
		this.explosivesCostPerTonChartOptions = this.getChartOptions('explosivesCostPerTon', 'explosivesCostPerTonGoal', 'column', 'Explosives Cost Per Ton');
		this.drillingCostPerTonChartOptions = this.getChartOptions('drillingCostPerTon', 'drillingCostPerTonGoal', 'bar', 'Drilling Cost Per Ton');
		this.drillAndBlastCostPerTonChartOptions = this.getChartOptions('drillAndBlastCostPerTon', 'drillAndBlastCostPerTonGoal', 'column', 'Drill And Blast Cost Per Ton');
		this.secondaryBreakageCostChartOptions = this.getChartOptions('secondaryBreakageCost', 'secondaryBreakageCostGoal', 'area', 'Secondary Breakage Cost');
	}


	private getChartOptions(label1: string, label2: string, chartType: string, yAxisLabel: string) {
		const dataBySiteName = {};
		this.siteNameAsXAxisLabels = [];
		this.filteredDrillAndBlastCosts.forEach(entry => {
			const siteName = entry.siteName;
			if (!dataBySiteName[siteName]) {
				dataBySiteName[siteName] = [];
			}
			dataBySiteName[siteName].push(entry);
		});
		
		const sumOfValueOfLabel1BysiteName = this.calculateSum(dataBySiteName,label1);
		const sumOfValueOfLabel2BysiteName = this.calculateSum(dataBySiteName,label2);
		
		for(const key in dataBySiteName){
			this.siteNameAsXAxisLabels.push(key);
		}

		const seriesData = [
			{ name: yAxisLabel , data: sumOfValueOfLabel1BysiteName },
			{ name: `${yAxisLabel} Goal`, data: sumOfValueOfLabel2BysiteName }
		]

		return this.chartOptions(chartType, 'Sites', this.siteNameAsXAxisLabels, yAxisLabel, seriesData);
	}

	calculateSum(data,label: string) {
		const totalSum = [];
			
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const entries = data[key];
				const sum = entries.reduce((acc, entry) => acc + Number(entry[label]), 0);
				totalSum.push(sum);
			}
		}
		return totalSum;
	}

	private chartOptions(chartType: string, xAxisTitle: string, xAxisCategories: string[], yAxisTitle: string, seriesData: any) {
		const yAxisLabel = yAxisTitle.includes('Cost') ? `${yAxisTitle}($)`: yAxisTitle;
		const options:Highcharts.Options = {
			chart: {
				type: chartType,
				style: {
					fontFamily: 'Lato'
				},
			},
			title: {
				text: `${yAxisTitle} by ${xAxisTitle}`,
				align: 'left'
			},
			xAxis: {
				categories: xAxisCategories,
				title: {
					text: xAxisTitle,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			yAxis: {
				title: {
					text: yAxisLabel,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			credits: {
				enabled: false
			},
			series: seriesData
		}

		return options;
  	}
}

