export const DrillAndBlastDataStructure = {
	pfAndTonData: {
		pfTonGraph: [
			{ dataField: 'powderFactorVolume', chartType: 'column', chartTitle: 'Powder Factor Volume', yAxisLabel: 'Power Factor Volume', xAxisLabel: 'Project Names', valueUnit:'powderFactorVolume' },
			{ dataField: 'totalVolume', chartType: 'line', chartTitle: 'Total Volume', yAxisLabel: 'Total Volume', xAxisLabel: 'Project Names', valueUnit:'volume' },
			{ dataField: 'tonnage', chartType: 'column', chartTitle: 'Total Tonnage', yAxisLabel: 'Total Tonnage', xAxisLabel: 'Project Names', valueUnit:'largeWeight' },
			{ dataField: 'powderFactorWeight', chartType: 'column', chartTitle: 'Powder Factor Weight', yAxisLabel: 'Powder Factor Weight', xAxisLabel: 'Project Names', valueUnit:'powderFactorWeight' },
			{ dataField: 'totalExplosiveWeight', chartType: 'column', chartTitle: 'Total Explosive Weight', yAxisLabel: 'Total Explosive', xAxisLabel: 'Project Names', isExplosiveGraph: true, valueUnit:'weight' }
		]
	},
	burdenAndSpacing: {
		burdenSpacinGraph: [
			{ dataField: 'averageSpacing', chartType: 'column', chartTitle: 'Average Spacing', yAxisLabel: 'Average Spacing', xAxisLabel: 'Project Names', valueUnit:'distance' },
			{ dataField: 'averagePatternBurden', chartType: 'line', chartTitle: 'Average Pattern Burden', dataFieldTwo:'designBurden', seriesType:'line', seriesType1:'line', yAxisLabel: 'Average Pattern Burden', xAxisLabel: 'Project Names', valueUnit:'distance', isCombineType:true, isDesignBurdenGraph:true },
		]
	},
	drillLengthAndDiameter: {
		drillDiameterGraph: [
			{ dataField: 'totalDrillLength', chartType: 'line', chartTitle: 'Total Drilling Length', yAxisLabel: 'Total Drilling Length', xAxisLabel: 'Project Names', valueUnit:'distance' },
			{ dataField: 'averageHoleDiameter', chartType: 'column', chartTitle: 'Average Hole Diameter', yAxisLabel: 'Hole Diameter', xAxisLabel: 'Project Names', valueUnit:'smallDistance' },
		]
	},
	holeCountAndLength: {
		holeCountGraph: [
			{ dataField: 'holeCount', chartType: 'column', chartTitle: 'Number Of Holes', yAxisLabel: 'Hole Count', xAxisLabel: 'Project Names', valueUnit:'count' },
			{ dataField: 'averageHoleLength', chartType: 'line', chartTitle: 'Average Hole Length', yAxisLabel: 'Average Hole Length', xAxisLabel: 'Project Names', valueUnit:'distance' },
			{ dataField: '', chartType: 'column', chartTitle: 'Average Laborers, Average Hours', dataFieldTwo: '', seriesType: 'column', seriesType1: 'column', isCombineType: true, isLabourGraph: true, yAxisLabel: 'Average Laborers, Average Hours', xAxisLabel: 'Site Details', valueUnit:'count' },
			{ dataField: '', chartType: 'column', chartTitle: 'Total Explosive Quantity', dataFieldTwo: '', seriesType: 'column', isTruckGraph: true, yAxisLabel: 'Explosive Amount', xAxisLabel: 'Truck Details', valueUnit:'weight' },
		],
	}
};

export const FielderAnalysisDataStructure = {
	fielderAnalyticsData: {
		fielderAnalyticsDataGraph: [
			{ dataField: 'totalDrilledHoles', chartType: 'column', chartTitle: 'Total Drilled Holes', yAxisLabel: 'Total Drilled Holes', xAxisLabel: 'Project Names', valueUnit:'count' },
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilled Length', yAxisLabel: 'Total Drilled Length', xAxisLabel: 'Project Names', valueUnit:'distance' },
			{ dataField: 'totalDrillingTime', chartType: 'line', chartTitle: 'Total Drilling Time', yAxisLabel: 'Drilling Time', xAxisLabel: 'Project Names', valueUnit:'hour' },
			{ dataField: 'averageTimeBetweenHoles', chartType: 'column', chartTitle: 'Average Time Between Holes', yAxisLabel: 'Average Time Between Holes', xAxisLabel: 'Project Names', valueUnit:'minute' },
			{ dataField: 'averageTimePerHole', chartType: 'line', chartTitle: 'Average Time Per Hole', yAxisLabel: 'Average Time Per Hole', xAxisLabel: 'Project Names', valueUnit:'minute' },
			{ dataField: 'averagePerDayDrill', chartType: 'column', chartTitle: 'Average Drilled Per Day', yAxisLabel: 'Average Per Day Drill', xAxisLabel: 'Project Names', valueUnit:'distance' },
		]
	},
	utilizationByShot: {
		utilizationByShotGraph: [
			{ dataField: 'utilization', chartType: 'column', chartTitle: 'Utilization', yAxisLabel: 'Utilization', xAxisLabel: 'Project Names', valueUnit:'percent' },
		],
	},
	drilledHolesByDrilledLength: {
		drilledHolesByDrilledLengthGraph: [
			{ dataField: 'totalHoles', chartType: 'zoomType', chartTitle: 'Total Holes, Total Drilled Holes', yAxisLabel: 'Total Holes, Total Drilled Holes', xAxisLabel: 'Project Names', dataFieldTwo: 'totalDrilledHoles', seriesType: 'line', seriesType1: 'column', isCombineType: true, valueUnit:'count' },
		],
	},
	totalHolesAndTotalDrilledHolesByShot: {
		totalHolesAndTotalDrilledHolesByShotGraph: [
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilled Holes, Total Drilled Length', yAxisLabel: 'Total Drilled Holes, Total Drilled Length', xAxisLabel: 'Project Names', dataFieldTwo: 'totalDrilledHoles', isCombineType: true, valueUnit:'count' },
		]
	},
	drillGraph: {
		drillGraphData: [
			{ dataField: 'totalDrilledHoles', chartType: 'column', chartTitle: 'Total Drilled Holes By Drill Machine Name', yAxisLabel: 'Total Drilled Holes', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit:'count' },
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilling Length By Drill Machine Name', yAxisLabel: 'Total Drilled Length', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit:'distance' },
			{ dataField: 'totalDrillingTime', chartType: 'line', chartTitle: 'Total Drilling Time By Drill Machine Name', yAxisLabel: 'Total Drilled Time', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit:'hour' },
		]
	}
};

export const FragmentationStructure = {
	fragmentationGraphData: {
		fragmentationGraph: [
			{ dataField: 'd50', chartType: 'column', chartTitle: 'D50, D80', dataFieldTwo: 'd80', seriesType: 'column', seriesType1: 'column', isCombineType: true, yAxisLabel: 'D50, D80', xAxisLabel: 'Project Names', valueUnit:'smallDistance' },
			{ dataField: 'fragmentationSizeRange', chartType: 'column', chartTitle: 'Fragmentation Size Ranges Chart', IsSizeRange: true, yAxisLabel: '%passing', xAxisLabel: 'Project Names', valueUnit:'count' },
			{ dataField: 'swellFactor', chartType: 'line', chartTitle: 'Swell Factor', yAxisLabel: 'Swell Factor', xAxisLabel: 'Project Names',  valueUnit:'count' },
			{ dataField: 'averageMuckpileVolume', chartType: 'line', chartTitle: 'Muckpile Volume', yAxisLabel: 'Muckpile Volume', xAxisLabel: 'Project Names', valueUnit: 'volume' },
			{ dataField: 'averageBurdenRelief', chartType: 'line', chartTitle: 'Average Burden Relief', yAxisLabel: 'Average Burden Relief', xAxisLabel: 'Project Names', valueUnit:'smallTimePerDistance' }		
		]
	}
};
